<template>
  <v-dialog v-model="innerValue" max-width="400">
    <v-card>
      <v-card-title>
        <v-icon>mdi-qrcode-scan</v-icon>
        QR-koodin skannaus
      </v-card-title>
      <v-card-text>
        <qrcode-stream
          @detect="onDetect"
          :key="`${idKey}_${innerValue}`"
        ></qrcode-stream>
        <div class="mt-2" style="height: 40px">
          <div v-if="this.code">
            <v-icon class="success text--white">mdi-check</v-icon>
            <strong class="ml-2">{{ code }}</strong>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="innerValue = false">Sulje</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "qr-code-scanner-dialog",
  emits: ["detect"],
  components: { QrcodeStream },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: this.value,
      code: "",
      timeoutId: null,
      idKey: Date.now(),
    };
  },
  methods: {
    async onDetect(codes) {
      const code = (await codes)?.content || "";
      this.$emit("detect", code);
      this.code = code;
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = setTimeout(() => {
        this.code = "";
        this.idKey = Date.now();
      }, 5000);
    },
  },
  watch: {
    value() {
      this.innerValue = this.value;
    },
    innerValue() {
      this.$emit("input", this.innerValue);
    },
  },
};
</script>
