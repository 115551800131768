import Vue from "vue";
import dayjs from "dayjs";
import Toast from "vue-toastification"; // Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { deleteToken, getMessaging } from "firebase/messaging";
import app from "@/plugins/firebase.app";

const toastOptions = {
  timeout: 2500,
};
Vue.use(Toast, toastOptions);

export default Vue.mixin({
  methods: {
    $capitalFirstLetter(s) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    $formatDate(date) {
      if (date === null) return null;
      return dayjs(date).format("DD.MM.YYYY");
    },

    $formatDateAndHourMinute(date) {
      if (date === null) return null;
      if (dayjs(date).isValid()) {
        return dayjs(date).format("DD.MM.YYYY HH:mm");
      } else {
        return "Päivämäärä ei ole oikein";
      }
    },
    $formatWeek(date) {
      if (date === null) return null;
      return dayjs(date).format("WW/YYYY");
    },
    $formatWeekDayDateAndHourMinute(date) {
      return dayjs(date).format("dd DD.MM.YYYY HH:mm");
    },
    $parseDateAsHelsinki(date) {
      return dayjs.tz(date, "YYYY-MM-DD HH:mm", "Europe/Helsinki");
    },
    $formatWeekDay(date) {
      return dayjs(date).format("dd");
    },
    $formatMonthYear(date) {
      return dayjs(date).format("MMMM YYYY");
    },
    async $logout() {
      await this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
      try {
        const messaging = getMessaging(app);
        await deleteToken(messaging);
      } catch (e) {
        console.error(e);
      }
    },
    $id(item) {
      return item?.id;
    },
    $uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    $deleteConfirm(title) {
      return this.$confirm(title, {
        buttonTrueText: "Kyllä",
        buttonTrueColor: "primary",
        buttonFalseText: "Ei",
        buttonFalseColor: "error",
      });
    },
    $hasRole(role) {
      return this.$userInfo?.customAttributes?.roles.includes(role);
    },
    $hasRoleIn(roles) {
      if (!roles) return true;
      return roles.some((r) =>
        this.$userInfo?.customAttributes?.roles.includes(r)
      );
      //return this.$userInfo?.customAttributes?.roles.includes(role);
    },
  },
  computed: {
    $pwa() {
      return (
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone ||
        document.referrer.includes("android-app://")
      );
    },
    $iOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    $user() {
      return this.$store.state.user;
    },
    $userInfo() {
      const userInfo = this.$store.state.user?.reloadUserInfo;
      try {
        userInfo.customAttributes = JSON.parse(userInfo.customAttributes);
      } catch (e) {
        //.
      }
      return userInfo;
    },
    $isUser() {
      return this.$userInfo?.customAttributes?.roles.includes("user");
    },
    $isAdmin() {
      return this.$userInfo?.customAttributes?.roles.includes("admin");
    },
    $isHallitus() {
      return this.$userInfo?.customAttributes?.roles.includes("hallitus");
    },
    $isNaisosasto() {
      return this.$userInfo?.customAttributes?.roles.includes("naisosasto");
    },
    $isOnlyNaisosasto() {
      return this.$isNaisosasto && !this.$isAdmin && !this.$isUser;
    },
    $isMissingUserRole() {
      return (
        !this.$userInfo?.customAttributes?.roles ||
        (!this.$userInfo?.customAttributes?.roles?.includes("user") &&
          !this.$userInfo?.customAttributes?.roles?.includes("naisosasto"))
      );
    },
    $isSuperAdmin() {
      let emailList = [
        "jonirautiainen@gmail.com",
        "joni.leino88@gmail.com",
        "jarnojjoensuu@gmail.com",
        "heikkila.kai65@gmail.com",
        "janne.leinonen@outlook.com",
      ];
      return emailList.includes(this.$userInfo.email);
    },
    $rules() {
      return {
        required: (value) => !!value || "Tämä kenttä ei voi olla tyhjä.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            pattern.test(value) || "Oikean muotoinen sähköposti vaaditaan."
          );
        },
      };
    },
  },
});
