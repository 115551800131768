<template>
  <basic-page fluid title="Käyttäjät">
    <v-row class="fill-height">
      <v-col cols="12" lg="10" offset-lg="1" offset-xl="3" xl="6">
        <v-card class="mt-5">
          <v-card-title
            >Käyttäjät
            <v-spacer />
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="users"
              :items-per-page="-1"
              :loading="loadingUsers"
            >
              <template v-slot:item.lastRefreshTime="{ item }">
                {{ $formatDateAndHourMinute(item.metadata.lastRefreshTime) }}
              </template>
              <template v-slot:item.lastSignInTime="{ item }">
                {{ $formatDateAndHourMinute(item.metadata.lastSignInTime) }}
              </template>
              <template v-slot:item.creationTime="{ item }">
                {{ $formatDateAndHourMinute(item.metadata.creationTime) }}
              </template>
              <template v-slot:item.customClaims="{ item }">
                <span v-if="item.customClaims && item.customClaims.roles">
                  {{ item.customClaims.roles.map((r) => r).join(", ") }}
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn icon @click="editUser(item)">
                  <v-icon>mdi mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-dialog v-model="editModal" max-width="600px" persistent>
          <v-card>
            <v-card-title>Muokkaa käyttäjää</v-card-title>
            <v-divider />
            <v-card-text>
              <v-container v-if="user">
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                      v-model="user.displayName"
                      hide-details
                      label="Nimi"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-checkbox
                      v-model="user.admin"
                      hint="Hälytysosasto: Näkee kaikki mitä järjestelmässä on"
                      label="Hälytysosasto: Pääkäyttäjä"
                      persistent-hint
                    ></v-checkbox>
                    <v-checkbox
                      v-model="user.user"
                      hint="Hälytysosasto: Näkee ilmoitustaulun, harjoitukset ja  turvallisuusilmoituksen"
                      label="Hälytysosasto: Käyttäjä"
                      persistent-hint
                    ></v-checkbox>
                    <v-checkbox
                      v-if="$isSuperAdmin"
                      v-model="user.hallitus"
                      hint="Hallitus: Näkee hallituksen asiat"
                      label="Hallitus: Käyttäjä"
                      persistent-hint
                    ></v-checkbox>
                    <v-checkbox
                      v-model="user.naisosasto"
                      hint="Tukiosasto"
                      label="Tukiosasto: Käyttäjä"
                      persistent-hint
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="editModal = false">
                Sulje
              </v-btn>
              <v-btn
                :loading="loadingSaveUser"
                color="primary"
                text
                @click="saveUser"
              >
                Tallenna
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";
import api from "@/api";
import { orderBy } from "lodash";

export default {
  name: "Kayttajat",
  components: { BasicPage },
  data: () => {
    return {
      loading: false,
      users: [],
      editModal: false,
      user: null,
      loadingSaveUser: false,
      loadingUsers: false,
      headers: [
        { text: "Nimi", value: "displayName" },
        { text: "Sähköposti", value: "email" },
        { text: "Käynyt viimeksi", value: "lastRefreshTime" },
        { text: "Kirjauduttu", value: "lastSignInTime" },
        { text: "Luotu", value: "creationTime" },
        { text: "Roolit", value: "customClaims" },

        { text: "", value: "actions", width: 100 },
      ],
    };
  },
  computed: {
    today() {
      return dayjs().format("LLLL");
    },
    henkilot() {
      return this.$store.state.henkilot;
    },
  },
  methods: {
    reloadAll() {
      this.reloadUsers();
    },
    async reloadUsers() {
      this.loadingUsers = true;
      let response = (await api.usersList()).data;
      const users = orderBy(
        response.users,
        ["displayName", "email"],
        ["asc", "asc"]
      );
      this.users = users;
      this.loadingUsers = false;
    },
    editUser(item) {
      this.user = {
        ...item,
        admin: item.customClaims?.roles?.includes("admin"),
        user: item.customClaims?.roles?.includes("user"),
        hallitus: item.customClaims?.roles?.includes("hallitus"),
        naisosasto: item.customClaims?.roles?.includes("naisosasto"),
      };
      this.editModal = true;
    },
    async saveUser() {
      const user = { ...this.user };
      user.customClaims = {
        roles: [
          user.admin && "admin",
          user.user && "user",
          user.hallitus && "hallitus",
          user.naisosasto && "naisosasto",
        ].filter(Boolean),
      };
      this.loadingSaveUser = true;
      try {
        let response = await api.userSave(user);
      } catch (e) {
        this.$toast("Käyttäjän päivittäminen ei onnistunut " + e.message, {
          type: "error",
        });
        this.loadingSaveUser = false;
        console.error(e);
        return;
      }
      this.loadingSaveUser = false;
      this.editModal = false;
      this.user = null;
      this.$toast("Käyttäjän päivitetty", {
        type: "success",
      });
      this.reloadUsers();
    },
  },
  mounted() {
    this.reloadAll();
  },
};
</script>

<style lang="scss" scoped></style>
