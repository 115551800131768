<template>
  <div :title="$formatDateAndHourMinute(participant.created)">
    {{ participant.full_name }}
    <span
      v-if="participant.info"
      class="caption"
      style="white-space: normal !important"
      >({{ participant.info }})</span
    >
    <small v-if="useAdminInterface"
      >&nbsp;{{ $formatDateAndHourMinute(participant.created) }}</small
    >

    <v-icon v-if="$user && $user.uid === participant.sub" color="yellow"
      >mdi mdi-star
    </v-icon>

    <v-btn
      v-if="useAdminInterface"
      :loading="loadingDelete"
      icon
      @click="deleteIlmoittautuminenHallitusByAnotherUser(participant)"
    >
      <v-icon color="error"> mdi mdi-delete</v-icon>
    </v-btn>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "IlmoittautuminenHallitusParticipant",
  data() {
    return {
      loadingDelete: false,
    };
  },
  props: {
    participant: Object,
    ilmoitus: Object,
    useAdminInterface: Boolean,
  },
  methods: {
    async deleteIlmoittautuminenHallitusByAnotherUser() {
      this.loadingDelete = true;
      try {
        const ilmoitus = (
          await api.ilmoitusHallitusIlmoittautuminenDeleteByAnotherUser({
            ilmoitusId: this.ilmoitus.id,
            sub: this.participant.sub,
          })
        ).data;
        this.$toast("Ilmoittautuminen poistettu", { type: "success" });
      } catch (e) {
        console.error(e);
        this.$toast("Ilmoittautumisen poistaminen ei onnistunut", {
          type: "error",
        });
      }
      this.$emit("deleted");
      this.loadingDelete = false;
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped></style>
