<template>
  <v-list-item-subtitle
    v-if="ilmoitus.type === 'ilmoittautuminen'"
    class="mt-5 text-wrap"
  >
    <v-divider />
    <div class="mt-4 mb-0">
      <h4 v-if="participants.length === 0" class="mb-6">
        Ei yhtään ilmoittautunutta
        <template v-if="ilmoitus.participantCount > 0"
          >(max {{ ilmoitus.participantCount }})
        </template>
      </h4>
      <h4 v-else>
        Ilmoittautuneet
        <template v-if="ilmoitus.participantCount > 0">
          {{ participants.length }}/{{ ilmoitus.participantCount }}
        </template>
      </h4>

      <v-text-field
        v-if="useAdminInterface"
        v-model="newParticipant"
        class="mt-2"
        dense
        label="Ilmoittautuneen nimi"
        outlined
        placeholder="Lisää uusi ilmoittautunut"
      >
        <template v-slot:append-outer>
          <div class="d-flex justify-center mt-n1 ml-n1">
            <v-btn
              :disabled="newParticipant.length <= 3"
              :loading="loadingManualIlmoittautuminen"
              color="primary"
              @click="openInfoDialog(true)"
            >
              <v-icon>mdi mdi-plus</v-icon>
            </v-btn>
          </div>
        </template>
      </v-text-field>

      <ul class="mb-2 mt-2" v-if="participants && participants.length > 0">
        <li v-for="participant in participants" :key="participant.sub">
          <ilmoittautuminen-participant
            :ilmoitus="ilmoitus"
            :participant="participant"
            :use-admin-interface="useAdminInterface"
            @deleted="$emit('reload')"
          />
        </li>
      </ul>
      <v-btn
        v-if="!ilmoitus._participant && !isFull"
        :loading="loadingIlmoittautuminen"
        color="primary"
        outlined
        small
        @click="openInfoDialog(false)"
        >Ilmoittaudu
      </v-btn>
      <v-btn
        v-if="ilmoitus._participant"
        :loading="loadingIlmoittautuminen"
        color="error"
        outlined
        small
        @click="deleteIlmoittautuminen"
        >Poista ilmoittautuminen
      </v-btn>
    </div>
    <v-dialog
      v-model="infoDialogOpen"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="500"
    >
      <v-card>
        <v-card-title>Ilmoittautumisen lisätiedot</v-card-title>
        <v-divider />
        <v-card-text>
          <v-text-field
            v-model="info"
            autofocus
            class="mt-5"
            label="Mahdolliset lisätiedot esim. 511 YJ tai 511 S1 yms."
          ></v-text-field>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" outlined @click="infoDialogOpen = false"
            >Sulje
          </v-btn>
          <v-btn
            :loading="loadingIlmoittautuminen"
            color="primary"
            outlined
            @click="saveFn"
            >Ilmoittaudu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item-subtitle>
</template>

<script>
import IlmoittautuminenParticipant from "@/components/Ilmoitustaulu/IlmoittautuminenParticipant";
import api from "@/api";

export default {
  name: "Ilmoittautuminen",
  components: { IlmoittautuminenParticipant },
  data() {
    return {
      loadingIlmoittautuminen: false,
      newParticipant: "",
      loadingManualIlmoittautuminen: false,
      info: "",
      infoDialogOpen: false,
      saveFn: null,
    };
  },
  props: {
    ilmoitus: Object,
    admin: Boolean,
  },
  methods: {
    openInfoDialog(manual) {
      this.saveFn = manual
        ? this.addNewIlmoittautuminen
        : this.createIlmoittautuminen;
      this.infoDialogOpen = true;
      this.info = "";
    },

    async addNewIlmoittautuminen() {
      this.loadingManualIlmoittautuminen = true;
      try {
        await api.ilmoitusIlmoittautuminen({
          ilmoitusId: this.ilmoitus.id,
          manual: true,
          name: this.newParticipant,
          info: this.info,
        });
        this.$toast("Ilmoittautuminen lisätty", { type: "success" });
      } catch (e) {
        console.error(e);
        this.$toast("Ilmoittautuminen ei onnistunut", { type: "error" });
        this.loadingManualIlmoittautuminen = false;
      }
      this.$emit("reload");
      this.loadingManualIlmoittautuminen = false;
    },
    async createIlmoittautuminen() {
      this.loadingIlmoittautuminen = true;
      try {
        await api.ilmoitusIlmoittautuminen({
          ilmoitusId: this.ilmoitus.id,
          info: this.info,
        });
        this.$toast("Ilmoittautuminen lisätty", { type: "success" });
      } catch (e) {
        console.error(e);
        this.$toast("Ilmoittautuminen ei onnistunut", { type: "error" });
        this.loadingIlmoittautuminen = false;
      }
      this.$emit("reload");
      this.infoDialogOpen = false;
      this.loadingIlmoittautuminen = false;
    },
    async deleteIlmoittautuminen() {
      this.loadingIlmoittautuminen = true;
      try {
        await api.ilmoitusIlmoittautuminenDelete({
          ilmoitusId: this.ilmoitus.id,
        });
        this.$toast("Ilmoittautuminen poistettu", { type: "success" });
      } catch (e) {
        console.error(e);
        this.$toast("Ilmoittautumisen poistaminen ei onnistunut", {
          type: "error",
        });
        this.loadingIlmoittautuminen = false;
      }

      this.$emit("reload");
      this.loadingIlmoittautuminen = false;
      //this.loadingIlmoittautuminen = false;
    },
  },
  computed: {
    participants() {
      return this.ilmoitus.participants || [];
    },
    useAdminInterface() {
      return this.admin && this.$isAdmin;
    },
    max() {
      return this.ilmoitus.participantCount || 0;
    },
    isFull() {
      return this.max > 0 && this.participants.length >= this.max;
    },
  },
};
</script>

<style scoped></style>
