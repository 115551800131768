<script>
import { firestore as db } from "@/plugins/firebase.app";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import KalustoEdit from "@/views/KalustoEdit.vue";
import PictureDialog from "@/views/PictureDialog.vue";
import QrCodeScannerDialog from "@/views/QrCodeScannerDialog.vue";

const locationsTable = "kalusto_sijainnit";
const equipmentTable = "kalusto";
const equipmentTypesTable = "kalusto_tyypit";
export default {
  name: "KalustoView",
  components: { QrCodeScannerDialog, PictureDialog, KalustoEdit },
  data() {
    return {
      locations: [], // Raw flat locations from Firestore
      openNodes: [], // Tracks which nodes are expanded
      selectedNodes: [], // Tracks which nodes are selected
      equipmentTypes: [],
      loadingEquipments: false,
      equipments: [],
      search: "", // Search term
      dialog: false, // Add/Edit dialog visibility
      dialogTitle: "", // Title of the dialog
      dialogData: {}, // Data being edited or added
      // Equipment dialog
      dialogEquipment: false,
      selectedPicture: null,
      selectedPictureDialog: false,
      loadingEquipmentSave: false,
      showQrCodeScanner: false,
      detectedCodes: null,
    };
  },
  watch: {
    dialogEquipment(value) {
      if (!value) {
        this.dialogEquipmentData = {};
      }
    },
    selectedPicture() {
      if (this.selectedPicture) {
        this.selectedPictureDialog = true;
      } else {
        this.selectedPictureDialog = false;
      }
    },
    search(val) {
      if (val) {
        this.openNodes = this.locations.map((node) => node.id);
      }
      if (val === "") {
        this.openNodes = [];
      }
    },
  },
  computed: {
    treeData() {
      // Step 1: Create a map of all locations with an initial structure
      const map = new Map(
        this.locations.map((loc) => [
          loc.id,
          { ...loc, children: [], _fullTree: "" },
        ])
      );

      // Step 2: Assign children to their parents
      map.forEach((node) => {
        if (node.parent) {
          const parent = map.get(node.parent);
          if (parent) {
            parent.children.push(node);
          }
        }
      });

      // Step 3: Build the tree with _fullTree calculated dynamically
      const tree = [];
      const calculateFullTree = (node, parentFullTree = "") => {
        const fullTree = parentFullTree
          ? `${parentFullTree} -> ${node.name}`
          : node.name;
        return {
          ...node,
          _name: node.name,
          _fullTree: fullTree,
          children: [
            ...node.children.map((child) => calculateFullTree(child, fullTree)),
            ...this.equipments
              .filter((e) => e.locationId === node.id)
              .map((e) => ({
                ...e,
                _name: [e.name, e.id].join(" - "),
                _fullTree: `${fullTree} -> ${e.name}`,
                _isEquipment: true,
              })),
          ],
        };
      };

      map.forEach((node) => {
        if (!node.parent) {
          tree.push(calculateFullTree(node)); // Root nodes start the recursion
        }
      });

      return tree;
    },
    equipmentTypeTree() {
      const map = new Map(
        this.equipmentTypes.map((type) => [
          type.id,
          { ...type, children: [], _fullTree: "" },
        ])
      );
      const tree = [];

      map.forEach((node) => {
        if (node.parent) {
          const parent = map.get(node.parent);
          node._fullTree = parent._fullTree
            ? [parent._fullTree, node.name].join(" -> ")
            : node.name;
          //map.get(node.parent)?.children?.push(node);
          tree.push(node);
        } else {
          node._fullTree = node.name;
          tree.push(node);
        }
      });
      return tree;
    },
  },
  methods: {
    // Fetch all locations from Firestore
    async fetchLocations() {
      const snapshot = await getDocs(
        query(collection(db, locationsTable), where("deleted", "==", false))
      );
      this.locations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },
    async fetchEquipments() {
      this.loadingEquipments = true;
      try {
        const snapshot = await getDocs(
          query(collection(db, equipmentTable), where("deleted", "==", false))
        );
        this.equipments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          icon: "mdi-toy-brick",
        }));
      } catch (e) {
        console.error(e);
        this.$toast("Kaluston haku epäonnistui", { type: "error" });
      }
      this.loadingEquipments = false;
    },
    // Fetch equipment types for autocomplete
    async fetchEquipmentTypes() {
      const snapshot = await getDocs(collection(db, equipmentTypesTable));
      this.equipmentTypes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },

    // Build hierarchical tree data

    // Add a new node
    addNode(parent) {
      this.dialogTitle = "Lisää uusi sijainti";
      this.dialogData = {
        name: "",
        parent: parent.id,
        _fullTree: parent._fullTree,
        deleted: false,
      };
      this.dialog = true;
    },

    // Edit an existing node
    editNode(item) {
      this.dialogTitle = "Muokkaa sijaintia";
      this.dialogData = { ...item };
      this.dialog = true;
    },

    // Save the node (add or edit)
    async saveNode() {
      if (!this.dialogData.name.trim()) {
        this.$toast("Sijainnin nimi on määritettävä", { type: "error" });
      }
      const data = {
        name: this.dialogData.name,
        parent: this.dialogData.parent,
        deleted: this.dialogData.deleted,
      };
      if (this.dialogData.id) {
        // Update existing location
        await updateDoc(doc(db, locationsTable, this.dialogData.id), data);
      } else {
        // Add new location
        await addDoc(collection(db, locationsTable), data);
      }
      this.dialog = false;
      this.fetchLocations(); // Refresh data
    },

    // Delete a node
    async deleteNode(item) {
      if (
        await this.$deleteConfirm(
          `Haluatko varmasti poistaa sijainnin ${item.name}?`
        )
      ) {
        if (item.children && item.children.length > 0) {
          this.$toast(
            "Sijaintia ei voi poistaa, koska sillä on alisijainteja",
            {
              type: "error",
            }
          );
          return;
        }
        await deleteDoc(doc(db, locationsTable, item.id));
        this.fetchLocations(); // Refresh data
      }
    },
    // Edit an existing node
    editEquipment(item) {
      /*if (item.typeId) {
        const type = this.equipmentTypeTree.find((t) => t.id === item.typeId);
        this.dialogEquipmentData.typeFullTree = type._fullTree;
      }
      this.dialogEquipment = true;*/
      this.dialogEquipment = true;
      this.$nextTick(() => {
        this.$refs?.kalustoEdit?.editEquipment(item.id);
      });
    },
    // Add equipment to the selected location
    addEquipment(item) {
      this.dialogEquipment = true;
      this.$nextTick(() => {
        this.$refs?.kalustoEdit?.addEquipment(item);
      });
    },
    openQrCodeScanner() {
      this.showQrCodeScanner = true;
    },
    async onDetect(code) {
      try {
        const snapshot = await getDoc(doc(db, equipmentTable, code));
        if (snapshot.exists()) {
          this.showQrCodeScanner = false;
          await this.$router.push({
            name: "KalustoById",
            params: { id: code },
            query: { fromQr: true },
          });
        } else {
          this.$toast("Kalustoa ei löytynyt annetulla ID-koodilla " + code, {
            type: "error",
          });
        }
      } catch (e) {
        console.error(e);
        this.$toast("Virhe haettaessa kalustoa", { type: "error" });
      }
    },
  },
  mounted() {
    this.fetchLocations();
    this.fetchEquipmentTypes();
    this.fetchEquipments();
  },
};
</script>

<template>
  <v-container fluid>
    <v-card class="fill-height">
      <v-card-title>
        <v-icon class="mr-2">mdi-bus-marker</v-icon>
        Kalusto
        <v-spacer />
        <v-btn icon @click="openQrCodeScanner"
          ><v-icon>mdi mdi-qrcode-scan</v-icon></v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Hae"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
          <v-btn
            @click="openNodes = locations.map((node) => node.id)"
            :disabled="openNodes.length === locations.length"
            text
            small
            class="mr-2"
          >
            <v-icon>mdi mdi-unfold-more-horizontal</v-icon> Avaa
          </v-btn>
          <v-spacer />
          <v-btn
            @click="openNodes = []"
            :disabled="openNodes.length === 0"
            text
            small
          >
            <v-icon>mdi mdi-unfold-less-horizontal</v-icon> Sulje
          </v-btn>
        </v-row>
      </v-card-text>
      <v-card-text class="pa-0">
        <v-progress-linear
          indeterminate
          v-if="loadingEquipments"
        ></v-progress-linear>
        <v-treeview
          v-model="selectedNodes"
          :items="treeData"
          :activatable="false"
          item-value="id"
          item-text="_name"
          open-on-click
          :open.sync="openNodes"
          :search="search"
          :selectable="false"
        >
          <template v-slot:label="{ item }">
            <v-tooltip nudge-left="70" left>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <div>{{ item.name }}</div>
                  <div v-if="item._isEquipment" class="font-italic">
                    {{ item.id }}
                  </div>
                </div>
              </template>
              <div>{{ item.name }}</div>
              <div v-if="item._isEquipment" class="font-italic">
                {{ item.id }}
              </div>
            </v-tooltip>
          </template>
          <template v-slot:prepend="{ item }">
            <v-img
              v-if="item.pictureThumbnail"
              :src="item.pictureThumbnail"
              max-height="80"
              max-width="80"
              contain
              @click="selectedPicture = item.picture"
            />
            <v-icon v-else-if="item.icon" style="width: 80px">{{
              item.icon
            }}</v-icon>
          </template>
          <template v-slot:append="{ item }">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                  <!-- A menu icon to open the menu -->
                </v-btn>
              </template>

              <!-- Menu content -->
              <v-list dense>
                <v-list-item v-if="!item._isEquipment" @click="addNode(item)">
                  <v-list-item-icon>
                    <v-icon class="mr-2">mdi-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Lisää sijainti</v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="item._isEquipment"
                  @click="editEquipment(item)"
                >
                  <v-list-item-icon>
                    <v-icon class="mr-2">mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Muokkaa kalustoa</v-list-item-content>
                </v-list-item>

                <v-list-item v-else @click="editNode(item)">
                  <v-list-item-icon>
                    <v-icon class="mr-2">mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Muokkaa sijaintia</v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="!item._isEquipment"
                  @click="deleteNode(item)"
                >
                  <v-list-item-icon>
                    <v-icon class="mr-2">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Poista sijainti</v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="!item._isEquipment"
                  @click="addEquipment(item)"
                >
                  <v-list-item-icon>
                    <v-icon class="mr-2">mdi-toy-brick-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Lisää kalusto</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>

    <!-- Add/Edit Location Dialog -->
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-5"> {{ dialogData._fullTree }}</v-col>
          </v-row>
          <v-text-field
            label="Sijainnin nimi"
            v-model="dialogData.name"
            autofocus
            required
          ></v-text-field>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" text>Sulje</v-btn>
          <v-btn color="primary" @click="saveNode">Tallenna</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Equipment Dialog -->
    <v-dialog
      v-model="dialogEquipment"
      max-width="900"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <kalusto-edit
        :dialog="true"
        ref="kalustoEdit"
        @close="
          () => {
            dialogEquipment = false;
            this.fetchEquipments();
          }
        "
      />
    </v-dialog>
    <picture-dialog v-model="selectedPicture" />
    <qr-code-scanner-dialog @detect="onDetect" v-model="showQrCodeScanner" />
  </v-container>
</template>

<style scoped lang="scss"></style>
