<script>
import { collection, getDocs } from "firebase/firestore";
import { firestore as db } from "@/plugins/firebase.app";

const equipmentTypesTable = "kalusto_tyypit";

export default {
  name: "KalustoTyyppiDialog",
  emits: ["select"],
  data() {
    return {
      // Type selection dialog data
      dialogTypeSelection: false,
      equipmentTypes: [], // Raw flat data for types
      selectedType: null, // Selected type in the treeview
      selection: [],
      search: null,
    };
  },
  watch: {
    selection(val) {
      if (val?.length > 1) {
        this.selection = [val[1] ?? val[0]];
      }
      if (val[0] !== this.selectedType) {
        this.selectedType = val[0];
        this.selection = val[0] ? [val[0]] : [];
      }
    },
    search() {},
  },
  computed: {
    // Build hierarchical tree data for types
    treeData() {
      const map = new Map(
        this.equipmentTypes.map((type) => [
          type.id,
          { ...type, children: [], _fullTree: "" },
        ])
      );
      const tree = [];

      map.forEach((node) => {
        if (node.parent) {
          const parent = map.get(node.parent);
          node._fullTree = parent._fullTree
            ? [parent._fullTree, node.name].join(" -> ")
            : node.name;
          map.get(node.parent)?.children?.push(node);
        } else {
          node._fullTree = node.name;
          tree.push(node);
        }
      });

      return tree;
    },
  },
  methods: {
    // Fetch equipment types and build the tree
    async fetchEquipmentTypes() {
      const snapshot = await getDocs(collection(db, equipmentTypesTable));
      this.equipmentTypes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },

    // Open the type selection dialog
    openTypeDialog() {
      this.dialogTypeSelection = true;
      this.selectedType = null;
      this.selection = [];
    },
    selectType(type) {
      this.selectedType = type;
    },

    confirmTypeSelection() {
      this.$emit("select", this.selectedType);
      this.dialogTypeSelection = false;
    },
  },
  mounted() {
    this.fetchEquipmentTypes(); // Fetch types on mount
  },
};
</script>

<template>
  <v-dialog v-model="dialogTypeSelection" max-width="600">
    <v-card>
      <v-card-title>Valitse tyyppi</v-card-title>
      <v-divider />
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Hae tyyppiä"
          clearable
        ></v-text-field>
        <v-treeview
          :items="treeData"
          v-model="selection"
          item-value="id"
          item-text="name"
          selectable
          :activatable="false"
          open-on-click
          return-object
          open-all
          selection-type="independent"
          :search="search"
          ref="treeview"
        >
        </v-treeview>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogTypeSelection = false">Peruuta</v-btn>
        <v-btn color="primary" @click="confirmTypeSelection">Valitse</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
