<template>
  <basic-page fluid title="Leirikoski">
    <v-row class="fill-height">
      <v-col cols="12" lg="12">
        <v-card class="mt-5">
          <v-card-title
            >Leirikoski
            <v-spacer />
          </v-card-title>
          <v-divider />
          <v-card-text class="d-flex justify-center">
            <iframe
              frameborder="0"
              height="600"
              scrolling="no"
              src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FHelsinki&mode=MONTH&title=Leirikosken%20varauskalenteri&showTz=1&showCalendars=1&src=NjAwb3NvNTFlY2ozbTc1M2FsMzA4cGliNThAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%233F51B5"
              style="border: solid 1px #777"
              width="800"
            ></iframe>
          </v-card-text>
          <v-divider />

          <v-card-title
            >Talonhenkilön tehtävät
            <v-spacer />
          </v-card-title>
          <v-divider />
          <v-card-text>
            <ol>
              <li>Nurmikon leikkaus 1 krt / vko</li>
              <li>
                Auraus ja lumenluonti. Yli 10cm lunta ilmoitus isännöitsijälle
              </li>
              <li>Hiekoitus</li>
              <li>Vuokralaisten yhteyshenkilö ongelmatilanteissa</li>
              <li>Ovien avaaminen / hälyttimet</li>
              <li>
                Siisteyden tarkastus viikottain sekä vuokralaisten jälkeen
              </li>
              <li>
                Porealtaan täyttö / esilämmitys tarvittaessa+ph mittaus.
                Merkinnät APP:iin!
              </li>
              <li>Takan lämmitys</li>
            </ol>
          </v-card-text>
          <v-card-title> Huomioitavaa </v-card-title>
          <v-card-text>
            <ol>
              <li>Talonmiehen käytössä on yhdistyksen puhelin.</li>
              <li>
                Puhelin tulee olla aina tavoitettavissa. Tarvittaessa
                soitonsiirto omaan numeroon. Numerosta tulee olla
                tavoitettavissa aina talonmies, varahenkilö tai sijainen.
              </li>
              <li>
                Talonmiesvuoro kestää maanantaista maanantaihin, vaihto
                hälytysosaston harjoituksissa.
              </li>
              <li>
                Vuoroja voi vaihtaa keskenään, tieto isännöitsijälle ja merkintä
                tähän listaan.
              </li>
              <li>
                Ongelmatilanteessa vuokralainen soittaa aina ensin
                talonmiehelle. Mikäli talonmies ei kykene selvittämään ongelmaa,
                soittaa hän isännöitsijälle.
              </li>
              <li>
                Talonmiehen puhelimen kalenterista selviää Leirikosken
                vuokraukset.
              </li>
              <li>
                Talonmiehen tehtävänä on organisoida viikottaiset toimet, mikäli
                hän ei itse niitä kykene hoitamaan. Ensisijaisesti työt tekevät
                talonmies tai vara, tarvittaessa myös muut henkilöt.
              </li>
              <li>
                Talonmiehen tulee tiedottaa ongelmatilanteista ja muista
                puutteista isännöitsijää.
              </li>
              <li>
                Tunnit merkitään asemalla olevaan erilliseen talonmiesvihkoon.
              </li>
            </ol>
          </v-card-text>
          <v-card-subtitle>
            Talonhenkilön puhelinnumero:
            <v-icon>mdi mdi-phone</v-icon>
            <a href="tel:+358449880968">+358449880968</a>
          </v-card-subtitle>
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Viikko</th>
                  <th>Talonhenkilö vuorossa</th>
                  <th>Varahenkilö</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(talonhenkilo, index) in talonhenkilot" :key="index">
                  <td
                    :class="[
                      talonhenkiloCurrentIndex === index && 'blue lighten-5',
                    ]"
                  >
                    {{ talonhenkilo[0] }}/2024
                  </td>
                  <td
                    :class="[
                      talonhenkiloCurrentIndex === index && 'blue lighten-5',
                    ]"
                  >
                    {{ talonhenkilo[1] }}
                  </td>
                  <td
                    :class="[
                      talonhenkiloCurrentIndex === index && 'blue lighten-5',
                    ]"
                  >
                    {{ talonhenkilo[2] }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-divider />
          <v-card-title
            >Porealtaan huolto
            <v-spacer />
            <div>
              <v-btn color="primary" rounded @click="newAllasData">
                <v-icon>mdi mdi-plus</v-icon> Uusi merkintä
              </v-btn>
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-data-table
              :headers="allasDataHeaders"
              :items="allasDatat"
              :items-per-page="52"
              :loading="loadingAllasData"
            >
              <template v-slot:item.viikko="{ item }">
                {{ $formatWeek(item.viikko) }}
              </template>
              <template v-slot:item.klooritabletti="{ item }">
                {{ $formatDate(item.klooritabletti) }}
              </template>
              <template v-slot:item.suodatinpesu="{ item }">
                {{ $formatDate(item.suodatinpesu) }}
              </template>
              <template v-slot:item.suodatinvaihto="{ item }">
                {{ $formatDate(item.suodatinvaihto) }}
              </template>
              <template v-slot:item.vedenvaihto="{ item }">
                {{ $formatDate(item.vedenvaihto) }}
              </template>
              <template v-slot:item.ph="{ item }">
                <span
                  v-if="item.ph > 7.6 || item.ph < 7.2"
                  class="error--text"
                  >{{ item.ph }}</span
                >
                <span v-else class="success--text">{{ item.ph }}</span>
              </template>
              <template v-slot:item.kloori="{ item }">
                <span
                  v-if="item.kloori > 1.0 || item.kloori < 0.6"
                  class="error--text"
                  >{{ item.kloori }}</span
                >
                <span v-else class="success--text">{{ item.kloori }}</span>
              </template>
              <template v-slot:item.name="{ item }">
                <span v-if="item.createdBy">
                  {{ item.createdBy.user }}
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  v-if="item.createdBy.sub === $user.uid"
                  icon
                  @click="editAllasData(item)"
                >
                  <v-icon>mdi mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-dialog
          v-model="editModal"
          :fullscreen="$vuetify.breakpoint.smAndDown"
          max-width="600px"
          persistent
        >
          <v-card>
            <v-card-title v-if="allasData"
              >{{ allasData.id ? "Muokkaa" : "Uusi" }} porealtaan
              huoltomerkintä{{ allasData.id ? "ä" : "" }}
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-container v-if="allasData">
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <modal-picker
                      v-model="allasData.viikko"
                      :allowed-dates="allowedDates"
                      label="Viikko"
                      type="week"
                    >
                      <template v-slot:append="">
                        <v-btn
                          :ripple="false"
                          class="mt-n1"
                          depressed
                          plain
                          text
                          @click="allasData.viikko = todayDate"
                          >Tänään
                        </v-btn>
                      </template>
                    </modal-picker>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <modal-picker
                      v-model="allasData.klooritabletti"
                      :allowed-dates="allowedDates"
                      label="Klooritabletti"
                    >
                      <template v-slot:append="">
                        <v-btn
                          :ripple="false"
                          class="mt-n1"
                          depressed
                          plain
                          text
                          @click="allasData.klooritabletti = todayDate"
                          >Tänään
                        </v-btn>
                      </template>
                    </modal-picker>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <modal-picker
                      v-model="allasData.suodatinpesu"
                      :allowed-dates="allowedDates"
                      label="Suodatinpesu"
                    >
                      <template v-slot:append="">
                        <v-btn
                          :ripple="false"
                          class="mt-n1"
                          depressed
                          plain
                          text
                          @click="allasData.suodatinpesu = todayDate"
                          >Tänään
                        </v-btn>
                      </template>
                    </modal-picker>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <modal-picker
                      v-model="allasData.suodatinvaihto"
                      :allowed-dates="allowedDates"
                      label="Suodatinvaihto"
                    >
                      <template v-slot:append="">
                        <v-btn
                          :ripple="false"
                          class="mt-n1"
                          depressed
                          plain
                          text
                          @click="allasData.suodatinvaihto = todayDate"
                          >Tänään
                        </v-btn>
                      </template>
                    </modal-picker>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <modal-picker
                      v-model="allasData.vedenvaihto"
                      :allowed-dates="allowedDates"
                      label="Vedenvaihto"
                    >
                      <template v-slot:append="">
                        <v-btn
                          :ripple="false"
                          class="mt-n1"
                          depressed
                          plain
                          text
                          @click="allasData.vedenvaihto = todayDate"
                          >Tänään
                        </v-btn>
                      </template>
                    </modal-picker>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                      v-model="allasData.ph"
                      hide-details
                      label="pH"
                      outlined
                      type="number"
                    />
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                      v-model="allasData.kloori"
                      hide-details
                      label="Kloori"
                      outlined
                      type="number"
                    />
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      v-model="allasData.huomiot"
                      hide-details
                      label="Huomiot"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="error" text @click="editModal = false">
                Sulje
              </v-btn>
              <v-btn
                :loading="loadingSaveAllasData"
                color="primary"
                text
                @click="saveAllasData"
              >
                Tallenna
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";
import api from "@/api";
import { orderBy } from "lodash";
import ModalPicker from "@/components/ModalPicker";

export default {
  name: "Leirikoski",
  components: { ModalPicker, BasicPage },
  data: () => {
    return {
      loading: false,
      loadingAllasData: false,
      loadingSaveAllasData: false,
      users: [],
      editModal: false,
      user: null,
      allasData: {
        viikko: null,
        klooritabletti: null,
        suodatinpesu: null,
        suodatinvaihto: null,
        vedenvaihto: null,
        ph: null,
        kloori: null,
        muuta: null,
        name: null,
      },
      allasDatat: [],
      allasDataHeaders: [
        { text: "Viikko", value: "viikko" },
        { text: "Klooritabletti", value: "klooritabletti" },
        { text: "Suodatinpesu", value: "suodatinpesu" },
        { text: "Suodatinvaihto", value: "suodatinvaihto" },
        { text: "Vedenvaihto", value: "vedenvaihto" },
        { text: "pH", value: "ph" },
        { text: "Kloori (CI)", value: "kloori" },
        { text: "Huomiot", value: "huomiot" },
        { text: "Nimi", value: "name" },

        { text: "", value: "actions", width: 100 },
      ],
      talonhenkilot: [
        ["1", "Koskela Esa", "Lindgren Ville"],
        ["2", "Latva Aimo", "Linna Mika"],
        ["3", "Leino Jouni", "Lähteenmäki Mika"],
        ["4", "Lindgren Ville", "Pentti Pasi"],
        ["5", "---------", "Helin Jarkko"],
        ["6", "Lähteenmäki Mika", "Raikisto Mari"],
        ["7", "Pentti Pasi", "Rautiainen Joni"],
        ["8", "Pöyhölä Sanna", "Heiskala Jani-Petteri"],
        ["9", "Raikisto Mari", "Reponen Simo"],
        ["10", "Rautiainen Joni", "Salonen Tomi"],
        ["11", "Reponen Matti", "Toivanen Tuula"],
        ["12", "Reponen Simo", "Uusikorpi Rami"],
        ["13", "Salonen Tomi", "Vehkalahti Max"],
        ["14", "Toivanen Tuula", "Vuorenpää Sami"],
        ["15", "Uusikorpi Rami", "Ylianttila Tomi"],
        ["16", "Vehkalahti Max", "Ylinen Jarkko"],
        ["17", "Vuorenpää Sami", "Halme Jari"],
        ["18", "Yli-Anttila Tomi", "Hanhikangas Arttu"],
        ["19", "Ylinen Jarkko", "Reponen Matti"],
        ["20", "Halme Jari", "Heiskala Juha-Pekka"],
        ["21", "Hanhikangas Arttu", "Heiskala Matti"],
        ["22", "Heiskala Jani-Petteri", "Pöyhölä Sanna"],
        ["23", "Heiskala Juha-Pekka", "Jalava Juuso"],
        ["24", "Heiskala Matti", "Koskela Esa"],
        ["25", "Helin Jarkko", "Latva Aimo"],
        ["26", "Jalava Juuso", "Leino Jouni"],
        ["27", "Koskela Esa", "Lindgren Ville"],
        ["28", "Latva Aimo", "Haapaniemi Eero"],
        ["29", "Leino Jouni", "Lähteenmäki Mika"],
        ["30", "Lindgren Ville", "Pentti Pasi"],
        ["31", "Haapaniemi Eero", "Helin Jarkko"],
        ["32", "Lähteenmäki Mika", "Raikisto Mari"],
        ["33", "Pentti Pasi", "Rautiainen Joni"],
        ["34", "Pöyhölä Sanna", "Heiskala Jani-Petteri"],
        ["35", "Raikisto Mari", "Reponen Simo"],
        ["36", "Rautiainen Joni", "Salonen Tomi"],
        ["37", "Reponen Matti", "Toivanen Tuula"],
        ["38", "Reponen Simo", "Uusikorpi Rami"],
        ["39", "Salonen Tomi", "Vehkalahti Max"],
        ["40", "Toivanen Tuula", "Vuorenpää Sami"],
        ["41", "Uusikorpi Rami", "Ylianttila Tomi"],
        ["42", "Vehkalahti Max", "Ylinen Jarkko"],
        ["43", "Vuorenpää Sami", "Halme Jari"],
        ["44", "Yli-Anttila Tomi", "Hanhikangas Arttu"],
        ["45", "Ylinen Jarkko", "Reponen Matti"],
        ["46", "Halme Jari", "Heiskala Juha-Pekka"],
        ["47", "Hanhikangas Arttu", "Heiskala Matti"],
        ["48", "Heiskala Jani-Petteri", "Pöyhölä Sanna"],
        ["49", "Heiskala Juha-Pekka", "Jalava Juuso"],
        ["50", "Heiskala Matti", "Koskela Esa"],
        ["51", "Helin Jarkko", "Latva Aimo"],
        ["52", "Jalava Juuso", "Leino Jouni"],
      ],
    };
  },
  computed: {
    todayDate() {
      return dayjs().format("YYYY-MM-DD");
    },
    today() {
      return dayjs();
    },
    talonhenkiloCurrentIndex() {
      return this.talonhenkilot.findIndex(
        (h) => parseInt(h[0]) == parseInt(this.today.format("W"))
      );
    },
  },
  methods: {
    allowedDates(val) {
      return this.today.isSameOrAfter(val, "day");
    },
    reloadAll() {
      this.reloadAllasData();
    },
    async reloadAllasData() {
      this.loadingAllasData = true;
      let response = (await api.allasDataList()).data;

      const allasDatat = orderBy(response, ["viikko"], ["desc"]);
      this.allasDatat = allasDatat;
      this.loadingAllasData = false;
    },
    newAllasData() {
      this.allasData = {
        viikko: dayjs().format("YYYY-MM-DD"),
        klooritabletti: null,
        suodatinpesu: null,
        suodatinvaihto: null,
        vedenvaihto: null,
        ph: null,
        kloori: null,
        muuta: null,
        name: null,
      };
      this.editModal = true;
    },
    editAllasData(item) {
      this.allasData = {
        ...item,
      };
      this.editModal = true;
    },
    async saveAllasData() {
      this.loadingSaveAllasData = true;
      try {
        let response = await api.allasDataSave(this.allasData);
      } catch (e) {
        this.$toast("Altaan datan päivittäminen ei onnistunut " + e.message, {
          type: "error",
        });
        this.loadingSaveAllasData = false;
        console.error(e);
        return;
      }
      this.loadingSaveAllasData = false;
      this.editModal = false;
      this.allasData = null;
      this.$toast("Altaan data tallennettu", {
        type: "success",
      });
      this.reloadAllasData();
    },
  },
  mounted() {
    this.reloadAll();
  },
};
</script>

<style lang="scss" scoped>
.current-talonhenkilo-row {
  td {
    background: var(--v-blue-base);
  }
}
</style>
