<template>
  <v-chip
    v-if="status"
    :color="
      status === 'NOT_STARTED'
        ? 'red lighten-2'
        : status === 'IN_PROGRESS'
        ? 'orange'
        : 'green'
    "
    :class="'white--text'"
    small
    >{{ statusText }}
  </v-chip>
</template>
<script>
export default {
  name: "tehtava-status-bagde",
  props: {
    status: {
      type: String,
      required: true,
      default: "",
    },
  },
  computed: {
    statusText() {
      return this.translateStatus(this.status);
    },
  },
  methods: {
    translateStatus(status) {
      switch (status) {
        case "NOT_STARTED":
          return "Ei aloitettu";
        case "IN_PROGRESS":
          return "Kesken";
        case "READY":
          return "Valmis";
        default:
          return status;
      }
    },
  },
};
</script>
