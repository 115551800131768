<script>
import api from "@/api";
import { orderBy } from "lodash";
import ModalPicker from "@/components/ModalPicker.vue";
import { firestore as db } from "@/plugins/firebase.app";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy as firestoreOrderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const maintenanceStatusChange = {
  maintenanceStatus: null, // Huollon tila
  maintenancePlace: null, // Missä
  maintenanceDate: null, // Milloin
  maintenancePerson: null, // Henkilö, joka on tehnyt huollon / hoitanut sen
  maintenanceCost: null, // Huollon kustannukset
  maintenanceDescription: null, // Huollon kuvaus
};
const batchSize = 10; // Adjust this number as needed
export default {
  name: "MaaraaikaishuoltoView",
  components: { ModalPicker },
  props: {
    kalusto: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Kalusto", value: "_kalusto" },
        { text: "Tyyppi", value: "type" },
        { text: "Tila", value: "status" },
        { text: "Vastuu", value: "assignedTo" },

        /*{ text: "Nimi", value: "name" },
        { text: "Kuvaus", value: "description" },*/
        { text: "Päivämäärä", value: "nextMaintenance" },
        { text: "", value: "actions" },
      ],
      huolto: null,
      users: [],
      loadingUsers: false,
      menu: false,
      huollot: [],
      kalustot: [],
      loadingHuoltoSave: false,
      loadingKalusto: false,
      loadingHuollot: false,
    };
  },
  computed: {
    loading() {
      return this.loadingKalusto || this.loadingHuollot;
    },
    maintenanceTypes() {
      return [
        { text: "Vuosihuolto", value: "VUOSIHUOLTO" },
        { text: "Kuukausihuolto", value: "KUUKAUSIHUOLTO" },
        { text: "Viikkohuolto", value: "VIIKKOHUOLTO" },
        { text: "Tarkastus", value: "TARKASTUS" },
        { text: "Korjaus", value: "KORJAUS" },
        { text: "Vaihto", value: "VAIHTO" },
      ];
    },
    statuses() {
      return [
        { text: "Suunniteltu", value: "SUUNNITELTU" },
        { text: "Aloitettu", value: "ALOITETTU" },
        { text: "Kesken", value: "KESKEN" },
        { text: "Myöhässä", value: "MYÖHÄSSÄ" },
        { text: "Viivästynyt", value: "VIIVÄSTYNYT" },
        { text: "Valmis", value: "VALMIS" },
        { text: "Valmis ongelmilla", value: "VALMIS ONGELMILLA" },
        { text: "Keskeytetty", value: "KESKEYTETTY" },
      ];
    },
    filteredHuollot() {
      let huollot = this.huollot;
      if (this.kalustot) {
        huollot = huollot.map((huolto) => {
          huolto._kalusto = this.kalustot.find((k) => k.id === huolto.kalusto);
          return huolto;
        });
      }
      return huollot;
    },
  },
  methods: {
    async reloadUsers() {
      this.loadingUsers = true;
      let response = (await api.usersList()).data;
      const users = orderBy(
        response.users,
        ["displayName", "email"],
        ["asc", "asc"]
      );
      users.forEach((u) => {
        u.text = u.displayName;
        u.value = u.email;
      });
      this.users = users;
      this.loadingUsers = false;
    },
    async saveHuolto() {
      this.loadingHuoltoSave = true;
      try {
        const huolto = {
          id: this.huolto?.id,
          name: this.huolto.name,
          type: this.huolto.type,
          description: this.huolto.description,
          kalusto: this.huolto.kalusto,
          nextMaintenance: this.huolto.nextMaintenance,
          assignedToUsers:
            this.huolto.assignedToUsers
              ?.map((u) => {
                const user = this.users.find((user) => user.email === u);
                if (!user) return null;
                return {
                  email: user.email,
                  sub: user.uid,
                  user: user.displayName,
                };
              })
              .filter(Boolean) || [],
          assignedTo: this.huolto.assignedTo,
          status: this.huolto.status,
          statusChanges: this.huolto.statusChanges,
        };
        if (this.huolto.id) {
          await updateDoc(doc(db, "huollot", this.huolto.id), huolto);
        } else {
          const docRef = await addDoc(collection(db, "huollot"), huolto);
          this.huolto.id = docRef.id;
        }
        this.huolto = null;
      } catch (e) {
        console.error(e);
        this.$toast("Huollon tallennus epäonnistui", { type: "error" });
      }
      this.loadingHuoltoSave = false;
      await this.reloadHuollot();
    },
    openNewHuolto(kalusto) {
      const huolto = {
        id: null,
        name: "",
        type: "VUOSIHUOLTO",
        description: "",
        kalusto: kalusto.id || this.kalusto?.id || null,
        nextMaintenance: "",
        assignedToUsers: [],
        assignedTo: null,
        status: "SUUNNITELTU",
        statusChanges: [],
      };
      this.huolto = huolto;
    },
    openHuolto(huolto) {
      this.huolto = huolto;
    },
    async fetchKalusto() {
      this.loadingKalusto = true;
      try {
        const kalustoIds = new Set(
          this.huollot.map((huolto) => huolto.kalusto)
        );

        const kalustoIdsArray = Array.from(kalustoIds);

        // Create an array of promises to fetch batches concurrently
        const batchPromises = [];

        for (let i = 0; i < kalustoIdsArray.length; i += batchSize) {
          const chunk = kalustoIdsArray.slice(i, i + batchSize);

          // Build the query using the modular API
          const kalustoCollection = collection(db, "kalusto");
          const kalustoQuery = query(
            kalustoCollection,
            where("id", "in", chunk)
          );

          // Fetch the data for this batch
          const batchPromise = getDocs(kalustoQuery)
            .then((querySnapshot) => {
              const batchData = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              return batchData;
            })
            .catch((error) => {
              console.error("Error fetching kalusto data:", error);
              return []; // Return an empty array in case of error for this batch
            });

          batchPromises.push(batchPromise);
        }

        // Wait for all batch promises to resolve
        const allKalustoData = await Promise.all(batchPromises);

        // Flatten the array of results from all batches
        const kalustoData = allKalustoData.flat();
        this.kalustot = kalustoData;

        //console.log(kalustoData); // The final combined data from all batches
      } catch (error) {
        this.$toast("Virhe kaluston hakemisessa", { type: "error" });
        console.error("Error fetching kalusto data:", error);
      }
      this.loadingKalusto = false;
    },
    async reloadHuollot() {
      this.loadingHuollot = true;
      if (this.kalusto?.id) {
        const snapshot = await getDocs(
          query(
            collection(db, "huollot"),
            firestoreOrderBy("nextMaintenance", "asc"),
            where("kalusto", "==", this.kalusto.id)
          )
        );
        this.huollot = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
      } else {
        const snapshot = await getDocs(
          collection(db, "huollot"),
          firestoreOrderBy("nextMaintenance", "asc")
        );
        this.huollot = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
      }
      this.loadingHuollot = false;
      try {
        await this.fetchKalusto();
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    this.reloadUsers();
    this.reloadHuollot();
  },
};
</script>

<template>
  <v-card class="fill-height" :flat="!!kalusto">
    <v-card-title v-if="!kalusto">
      <v-btn icon class="mr-2" v-if="huolto" @click="huolto = null"
        ><v-icon>mdi mdi-arrow-left</v-icon></v-btn
      >
      <v-icon class="mr-2">mdi-calendar-clock</v-icon>
      Määräaikaishuollot
    </v-card-title>
    <v-card-title v-if="kalusto">
      <v-btn icon class="mr-2" v-if="huolto" @click="huolto = null"
        ><v-icon>mdi mdi-arrow-left</v-icon></v-btn
      >
      {{ kalusto ? `${kalusto.name}` : "" }}
    </v-card-title>
    <v-card-subtitle v-if="kalusto">
      <div>
        {{ kalusto ? `${kalusto.id}` : "" }}
      </div>
      {{ kalusto ? `${kalusto.location}` : "" }}
    </v-card-subtitle>
    <v-divider />
    <v-card-text v-if="!huolto">
      <v-data-table
        :headers="headers"
        :items="filteredHuollot"
        :loading="loading"
      >
        <template v-slot:item.type="{ item }">
          {{
            maintenanceTypes.find((s) => s.value === item.type)?.text ||
            item.type
          }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ statuses.find((s) => s.value === item.status)?.text || item.type }}
        </template>
        <template v-slot:item._kalusto="{ item }">
          <span class="text-subtitle-2">{{ item._kalusto?.name }}</span>
          <div class="text-caption mt-1">
            {{ item._kalusto?.id }}
          </div>
          <div class="text-caption">
            {{ item._kalusto?.location }}
          </div>
        </template>
        <template v-slot:item.nextMaintenance="{ item }">
          <span>
            {{ $formatDate(item.nextMaintenance) }}
          </span>
        </template>
        <template v-slot:item.assignedTo="{ item }">
          <span>
            {{
              [
                ...(item.assignedToUsers || [])?.map((u) => u.user),
                item.assignedTo,
              ]
                .filter(Boolean)
                .join(", ") || ""
            }}
          </span>
        </template>
        <template v-slot:top>
          <v-row>
            <v-col cols="11">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Hae"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn color="primary" @click="openNewHuolto">
                <v-icon>mdi mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="openHuolto(item)">
            <v-icon>mdi mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-text v-if="huolto">
      <!-- Form for Huolto -->
      <v-row>
        <!-- Type -->
        <v-col cols="12" sm="6">
          <v-select
            v-model="huolto.type"
            :items="maintenanceTypes"
            label="Huollon tyyppi"
            required
            outlined
            hide-details
            :rules="[(v) => !!v || 'Huollon tyyppi on pakollinen']"
          ></v-select>
        </v-col>
        <!-- Next Maintenance Date -->
        <v-col cols="12" md="6">
          <modal-picker
            type="date"
            editable-date
            required
            v-model="huolto.nextMaintenance"
            label="Huollon päivämäärä"
          />
        </v-col>

        <!-- Status -->
        <v-col cols="12" sm="6">
          <v-select
            v-model="huolto.status"
            :items="statuses"
            label="Huollon tila"
            required
            hide-details
            outlined
            :rules="[(v) => !!v || 'Huollon tila on pakollinen']"
          ></v-select>
        </v-col>
        <!-- Name -->
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="huolto.name"
            label="Huollon nimi"
            outlined
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Description -->
        <v-col cols="12">
          <v-textarea
            v-model="huolto.description"
            label="Huollon kuvaus"
            outlined
            hide-details
          ></v-textarea>
        </v-col>

        <!-- Assigned To User -->
        <v-col cols="12" md="6">
          <v-autocomplete
            :loading="loadingUsers"
            v-model="huolto.assignedToUsers"
            label="Vastuuhenkilöt palokunnasta"
            outlined
            hide-details
            multiple
            :items="users"
          />
        </v-col>

        <!-- Assigned To -->
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="huolto.assignedTo"
            outlined
            hide-details
            label="Vastuuhenkilö / organisaatio"
          ></v-text-field>
        </v-col>

        <!-- Submit Button -->
        <v-col cols="12" class="d-flex justify-end"> </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mb-2" />
    <v-card-actions v-if="huolto">
      <v-spacer />
      <v-btn color="primary" @click="saveHuolto" :loading="loadingHuoltoSave">
        Tallenna huolto
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss"></style>
