<template>
  <basic-page title="Turvallisuusilmoitus">
    <v-row class="fill-height">
      <v-col
        :class="[$vuetify.breakpoint.smAndDown && 'pa-0']"
        cols="12"
        lg="8"
        offset-lg="2"
        offset-xl="3"
        xl="6"
      >
        <v-stepper v-model="step" vertical>
          <v-card-title>Turvallisuusilmoitus</v-card-title>
          <v-stepper-step :complete="step > 1" step="1" @click="step = 1">
            <span>Tyyppi</span>
            <small v-if="vaaratilanne.type">{{ vaaratilanne.type }}</small>
          </v-stepper-step>
          <v-stepper-content step="1">
            <div class="mt-3">
              <v-btn-toggle
                v-model="vaaratilanne.type"
                :class="['flex-column']"
                color="info"
                dense
                mandatory
              >
                <v-btn value="Vaaratilanne">Vaaratilanne</v-btn>
                <v-btn value="Turvallisuuspoikkeama"
                  >Turvallisuuspoikkeama
                </v-btn>
                <v-btn value="Hyvä käytäntö">Hyvä käytäntö</v-btn>
                <v-btn value="Tapaturma">Tapaturma</v-btn>
              </v-btn-toggle>
            </div>
            <buttons
              :step="step"
              @save="save"
              @change-step="(input) => (this.step = this.step + input)"
            />
          </v-stepper-content>
          <v-stepper-step :complete="step > 2" step="2" @click="step = 2">
            <span>Päivämäärä</span>
            <small v-if="vaaratilanne.date">{{
              $formatDate(vaaratilanne.date)
            }}</small>
          </v-stepper-step>
          <v-stepper-content step="2">
            <div class="mt-3">
              <v-date-picker
                v-model="vaaratilanne.date"
                :allowed-dates="allowedDates"
                color="green lighten-1"
                full-width
                header-color="primary"
              ></v-date-picker>
            </div>
            <buttons
              :step="step"
              @save="save"
              @change-step="(input) => (this.step = this.step + input)"
            />
          </v-stepper-content>

          <v-stepper-step :complete="step > 3" step="3" @click="step = 3">
            <span>Kellon aika</span>
            <small>{{ vaaratilanne.time }}</small>
          </v-stepper-step>

          <v-stepper-content class="step-3" step="3">
            <v-time-picker
              v-model="vaaratilanne.time"
              format="24hr"
              full-width
            />
            <buttons
              :step="step"
              @save="save"
              @change-step="(input) => (this.step = this.step + input)"
            />
          </v-stepper-content>

          <v-stepper-step :complete="step > 4" step="4" @click="step = 4">
            <span>Tapahtumapaikka</span>
            <small>{{ vaaratilanne.location }}</small>
          </v-stepper-step>

          <v-stepper-content step="4">
            <v-text-field
              ref="locationTextField"
              v-model="vaaratilanne.location"
              full-width
              outlined
              placeholder="Syötä tapahtumapaikka"
              @keyup.enter="step = step + 1"
            />
            <buttons
              :step="step"
              @save="save"
              @change-step="(input) => (this.step = this.step + input)"
            />
          </v-stepper-content>

          <v-stepper-step :complete="step > 5" step="5" @click="step = 5">
            <span>Tapahtuman kuvaus ja toimenpiteet</span>
            <small>{{ vaaratilanne.description }}</small>
          </v-stepper-step>
          <v-stepper-content step="5">
            <div class="mb-2 caption">
              Kerro kuvaus tapahtumasta, sekä onko tehty jotain toimenpiteitä
              asian korjaamiseksi.
            </div>
            <v-textarea
              ref="descriptionTextField"
              v-model="vaaratilanne.description"
              full-width
              outlined
              auto-grow
              placeholder="Tapahtuman kuvaus ja toimenpiteet"
            />
            <buttons
              :step="step"
              @save="save"
              @change-step="(input) => (this.step = this.step + input)"
            />
          </v-stepper-content>

          <v-stepper-step :complete="step > 6" step="6" @click="step = 6">
            <span>Kuvat</span>
            <small v-if="vaaratilanne.files.length > 0"
              >{{ vaaratilanne.files.length }} kpl</small
            >
          </v-stepper-step>
          <v-stepper-content step="6">
            <file-upload
              ref="fileupload"
              accepted-file-types="image/*"
              label="Paina tästä lisätäksesi kuvia. Voit lisätä 3 kuvaa. "
              :max-files="3"
            />
            <v-alert
              >Mikäli haluat lähettää enemmän kuin 3 kuvaa, vastaa
              turvallisuusilmoituksen jälkeen saamaasi
              vahvistussähköpostiin.</v-alert
            >
            <buttons
              :step="step"
              @save="save"
              @change-step="(input) => (this.step = this.step + input)"
            />
          </v-stepper-content>

          <v-stepper-step :complete="step > 7" step="7" @click="step = 7">
            <span>Seuraukset</span>
            <small v-if="vaaratilanne.consequences">{{
              vaaratilanne.consequences.join(", ")
            }}</small>
          </v-stepper-step>
          <v-stepper-content step="7">
            <v-btn-toggle
              v-model="vaaratilanne.consequences"
              :class="['flex-column']"
              color="info"
              dense
              multiple
            >
              <v-btn value="Ihmiselle">Ihmiselle</v-btn>
              <v-btn value="Ympäristölle">Ympäristölle</v-btn>
              <v-btn value="Omaisuudelle">Omaisuudelle</v-btn>
            </v-btn-toggle>
            <buttons
              :step="step"
              @save="save"
              @change-step="(input) => (this.step = this.step + input)"
            />
          </v-stepper-content>

          <!--<v-stepper-step :complete="step > 7" step="7">
            <span>Toimenpiteet</span>
            <small v-if="vaaratilanne.consequences">{{
                vaaratilanne.consequences.join(", ")
              }}</small>
          </v-stepper-step>
          <v-stepper-content step="7">
            <v-btn-toggle
                v-model="vaaratilanne.consequences"
                color="info"
                dense
                :class="[$vuetify.breakpoint.smAndDown && 'flex-column']"
            >
              <v-btn value="Korjattu">Korjattu, miten?</v-btn>
              <v-btn value="Vaatii toimenpiteitä">Vaatii toimenpiteitä?</v-btn>
            </v-btn-toggle>
            <v-textarea
                outlined
                full-width
                placeholder="Tapahtuman kuvaus ja toteutetut toimenpiteet"
                v-model="vaaratilanne.description"
                ref="descriptionTextField"
            />
            <buttons
                :step="step"
                @change-step="(input) => (this.step = this.step + input)"
                @save="save"
            />
          </v-stepper-content>-->

          <v-stepper-step :complete="step > 8" step="8" @click="step = 8">
            Lähetä
          </v-stepper-step>
          <v-stepper-content step="8">
            <small>
              Turvallisuusilmoitus lähetetään tiedoksi
              työsuojeluorganisaatiolle.
            </small>

            <buttons
              :loading="loading"
              :step="step"
              @save="save"
              @change-step="(input) => (this.step = this.step + input)"
            />
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";

import Buttons from "@/components/Vaaratilanne/Buttons";
import FileUpload from "@/components/FileUpload";
import api from "@/api";

export default {
  name: "Vaaratilannehavainto",
  components: { FileUpload, Buttons, BasicPage },
  data: () => {
    return {
      step: 1,
      loading: false,
      vaaratilanne: {
        date: null,
        time: null,
        location: null,
        description: null,
        files: [],
        consequences: [],
        type: "Vaaratilanne",
      },
      successSnackbar: false,
      errorSnackbar: false,
    };
  },
  computed: {
    today() {
      return dayjs();
    },
  },
  methods: {
    allowedDates(val) {
      return this.today.isSameOrAfter(val, "day");
    },

    async save() {
      this.loading = true;

      const files = await this.$refs.fileupload.getFiles();

      try {
        const vaaratilannehavainto = (
          await api.vaaratilannehavaintoSend({
            ...this.vaaratilanne,
            files: files,
          })
        ).data;
        this.$toast(
          "Kiitos turvallisuusilmoituksestasi! Saat kopion sähköpostiosoitteeseesi.",
          { type: "success" }
        );
        this.$router.push({ name: "Ilmoitustaulu" });
      } catch (e) {
        console.error(e);
        this.$toast(
          "Jokin meni pieleen lähetyksessä. Ole hyvä ja yritä uudelleen!",
          { type: "error" }
        );
      }

      this.loading = false;
    },
  },
  created() {},
  watch: {
    step() {
      if (this.step === 3) {
        this.$refs.locationTextField.focus();
      }
      if (this.step === 4) {
        this.$refs.descriptionTextField.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .step-3 {
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding-right: 36px !important;
  }
}

.v-item-group {
  border-radius: 0 !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  border-width: 1px;
  border-style: solid;

  button {
    border-radius: 0 !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
    border-width: 1px 0 !important;
  }
}
</style>
