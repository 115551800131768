<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card v-if="innerValue">
      <v-card-text class="pt-5">
        <v-img :src="innerValue" contain max-height="80vh" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="innerValue = null">Sulje</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "picture-dialog",
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  computed: {
    dialog() {
      return this.innerValue !== null;
    },
  },
  emits: ["input"],
  watch: {
    value() {
      this.innerValue = this.value;
    },
    innerValue() {
      this.$emit("input", this.innerValue);
    },
  },
};
</script>
