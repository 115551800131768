<template>
  <v-card flat>
    <v-tabs v-model="tab">
      <v-tab>Perustiedot</v-tab>
      <v-tab v-if="!equipment?._new">Huolto</v-tab>
    </v-tabs>
    <v-divider />

    <v-card-text v-if="loadingEquipment">
      <v-skeleton-loader type="card-heading, list-item, list-item, list-item" />
    </v-card-text>
    <v-tabs-items v-model="tab" v-if="!loadingEquipment">
      <v-tab-item>
        <v-card>
          <v-card-title
            >{{ equipment ? `${equipment.name}` : "" }}
          </v-card-title>
          <v-card-subtitle>
            <div>{{ equipment.id }}</div>
            {{ equipment.location }}
          </v-card-subtitle>
          <v-card-text>
            <v-divider class="mb-4" />
            <v-text-field
              label="ID"
              v-model="equipment.id"
              required
              outlined
              :disabled="!equipment._new"
              append-icon="mdi-cog-play-outline"
              @click:append="generateId"
              append-outer-icon="mdi-qrcode-scan"
              @click:append-outer="showQrCodeScanner = true"
            >
            </v-text-field>
            <v-text-field
              label="Kaluston nimi"
              v-model="equipment.name"
              required
              outlined
            ></v-text-field>

            <v-text-field
              label="Tyyppi"
              v-model="equipment.typeFullTree"
              readonly
              outlined
              @click="openTypeDialog"
              append-icon="mdi-chevron-right"
              @click:append="openTypeDialog"
            >
            </v-text-field>

            <v-text-field
              label="Valmistaja"
              v-model="equipment.manufacturer"
              outlined
            ></v-text-field>

            <v-text-field
              label="Ostopäivä / Vastaanottopäivä"
              v-model="equipment.purchaseDate"
              outlined
              type="date"
            ></v-text-field>

            <v-textarea
              label="Kuvaus"
              v-model="equipment.description"
              outlined
              auto-grow
            ></v-textarea>
            <v-textarea
              label="Muistiinpanot"
              v-model="equipment.notes"
              outlined
              auto-grow
            ></v-textarea>

            <div class="mb-3">
              <label for="pictureUpload">Kuva:</label>
              <br />
              <input
                type="file"
                id="pictureUpload"
                @change="onPictureSelected"
              />

              <div class="mt-5" v-if="equipment.pictureThumbnail">
                <v-img
                  :src="equipment.pictureThumbnail"
                  max-height="200"
                  contain
                  @click="selectedPicture = equipment.picture"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <maaraaikaishuolto-view
            v-if="equipment.id"
            :key="equipment.id"
            :kalusto="equipment"
          ></maaraaikaishuolto-view>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>

    <v-divider v-if="tab === 0" />
    <v-card-actions v-if="tab === 0">
      <v-spacer></v-spacer>
      <v-btn text v-if="dialog" @click="$emit('close')">Sulje</v-btn>
      <v-btn
        color="primary"
        @click="saveEquipment"
        :loading="loadingEquipmentSave"
        >Tallenna
      </v-btn>
    </v-card-actions>
    <picture-dialog v-model="selectedPicture" />
    <kalusto-tyyppi-dialog
      ref="kalustoTyyppiDialog"
      @select="confirmTypeSelection"
    />
    <qr-code-scanner-dialog @detect="onDetect" v-model="showQrCodeScanner" />
  </v-card>
</template>
<script>
import MaaraaikaishuoltoView from "@/views/MaaraaikaishuoltoView.vue";
import { firestore as db, kalustoStorage } from "@/plugins/firebase.app";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import PictureDialog from "@/views/PictureDialog.vue";
import KalustoTyyppiDialog from "@/components/KalustoTyyppiDialog.vue";
import QrCodeScannerDialog from "@/views/QrCodeScannerDialog.vue";

const equipmentTable = "kalusto";
const equipmentTypesTable = "kalusto_tyypit";
export default {
  name: "kalusto-edit",
  components: {
    QrCodeScannerDialog,
    KalustoTyyppiDialog,
    PictureDialog,
    MaaraaikaishuoltoView,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingEquipment: false,
      loadingEquipmentSave: false,
      equipmentTypes: [],
      selectedPicture: null,
      showQrCodeScanner: false,
      equipment: {
        id: "",
        typeId: null,
        name: "",
        location: location._fullTree,
        locationId: location.id,
        description: "",
        notes: "",
        purchaseDate: "",
        manufacturer: "",
        picture: "",
        pictureFile: null,
        deleted: null,
        typeFullTree: null,
        _new: true,
      },
      tab: 0,
    };
  },
  emits: ["close"],
  computed: {
    equipmentTypeTree() {
      const map = new Map(
        this.equipmentTypes.map((type) => [
          type.id,
          { ...type, children: [], _fullTree: "" },
        ])
      );
      const tree = [];

      map.forEach((node) => {
        if (node.parent) {
          const parent = map.get(node.parent);
          node._fullTree = parent._fullTree
            ? [parent._fullTree, node.name].join(" -> ")
            : node.name;
          //map.get(node.parent)?.children?.push(node);
          tree.push(node);
        } else {
          node._fullTree = node.name;
          tree.push(node);
        }
      });
      return tree;
    },
  },
  methods: {
    async fetchEquipmentTypes() {
      const snapshot = await getDocs(collection(db, equipmentTypesTable));
      this.equipmentTypes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },

    resetEquipment() {
      this.equipment = {
        id: "",
        typeId: null,
        name: "",
        location: null,
        locationId: null,
        description: "",
        notes: "",
        purchaseDate: "",
        manufacturer: "",
        picture: "",
        pictureFile: null,
        deleted: null,
        typeFullTree: null,
        _new: true,
      };
    },
    addEquipment(location) {
      this.resetEquipment();
      this.equipment.location = location._fullTree;
      this.equipment.locationId = location.id;
    },
    async editEquipment(id, fromQr = false) {
      this.resetEquipment();
      await this.fetchEquipment(id, fromQr);
    },

    async fetchEquipment(id, fromQr = false) {
      this.loadingEquipment = true;
      try {
        const snapshot = await getDoc(doc(db, equipmentTable, id));
        if (snapshot.exists()) {
          this.equipment = { ...snapshot.data(), typeFullTree: null };
          const type = this.equipmentTypeTree.find(
            (t) => t.id === this.equipment.typeId
          );
          if (type) {
            this.equipment.typeFullTree = type._fullTree;
          }
        } else {
          this.$toast("Kalustoa ei löytynyt annetulla ID-koodilla " + id, {
            type: "error",
          });
        }
      } catch (e) {
        console.error(e);
        this.$toast("Kaluston haku epäonnistui", { type: "error" });
      }
      this.loadingEquipment = false;
    },
    generateId() {
      const timestamp = Date.now();
      this.equipment.id = `NO51${String(timestamp).substr(-6)}`;
    },
    onDetect(code) {
      this.equipment.id = code;
      this.showQrCodeScanner = false;
    },
    // Handle image file selection
    onPictureSelected(e) {
      const file = e.target.files[0];
      this.equipment.pictureFile = file;
    },
    async uploadEquipmentPicture(file) {
      if (!file) return "";
      if (!this.equipment.id) {
        this.$toast("Kaluston ID on pakollinen", { type: "error" });
        return;
      }
      const date = Date.now();
      const storage = kalustoStorage;
      const originalFilePath = `kalusto-kuvat/${this.equipment.id}/${date}-${file.name}`;
      const thumbnailFilePath = `kalusto-kuvat/${this.equipment.id}/${date}-thumbnail-${file.name}`;

      // Create a thumbnail of the image
      const thumbnail = await this.createThumbnail(file);

      // Upload original image to Firebase Storage
      const originalStorageRef = ref(storage, originalFilePath);
      await uploadBytes(originalStorageRef, file);

      // Upload thumbnail image to Firebase Storage
      const thumbnailStorageRef = ref(storage, thumbnailFilePath);
      await uploadBytes(thumbnailStorageRef, thumbnail);

      // Get download URLs for both images
      const originalDownloadURL = await getDownloadURL(originalStorageRef);
      const thumbnailDownloadURL = await getDownloadURL(thumbnailStorageRef);

      // Return the URLs or do whatever is necessary
      return {
        originalURL: originalDownloadURL,
        thumbnailURL: thumbnailDownloadURL,
      };
    },

    createThumbnail(file) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();

        // When the file is read as a data URL, load it into the image element
        reader.onload = (event) => {
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Resize the image (you can modify the width and height)
            const width = 150; // Thumbnail width
            const height = (img.height / img.width) * width; // Maintain aspect ratio
            canvas.width = width;
            canvas.height = height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0, width, height);

            // Convert the canvas to a Blob (thumbnail file)
            canvas.toBlob((blob) => {
              if (blob) {
                resolve(blob); // Resolve with the thumbnail Blob
              } else {
                reject("Error generating thumbnail");
              }
            }, "image/jpeg"); // You can also use other image formats like 'image/png'
          };
          img.src = event.target.result; // Set image source to the file data URL
        };

        reader.onerror = (error) => reject(error);

        // Read the file as a data URL
        reader.readAsDataURL(file);
      });
    },

    // Save equipment
    async saveEquipment() {
      if (!this.equipment.name.trim()) {
        this.$toast("Varusteen nimi on pakollinen", { type: "error" });
        return;
      }
      this.loadingEquipmentSave = true;

      try {
        const pictureURLs = await this.uploadEquipmentPicture(
          this.equipment.pictureFile
        );

        const equipmentData = {
          id: this.equipment.id || null,
          name: this.equipment.name || null,
          location: this.equipment.location || null,
          locationId: this.equipment.locationId || null,
          description: this.equipment.description || null,
          notes: this.equipment.notes || null,
          typeId: this.equipment.typeId || null,
          purchaseDate: this.equipment.purchaseDate || null,
          manufacturer: this.equipment.manufacturer || null,
          picture: pictureURLs?.originalURL || this.equipment.picture || null,
          pictureThumbnail:
            pictureURLs?.thumbnailURL ||
            this.equipment.pictureThumbnail ||
            null,
          createdAt: new Date().toISOString(),
          deleted: false,
        };

        const foundInDatabase = (
          await getDocs(
            query(
              collection(db, equipmentTable),
              where("id", "==", equipmentData.id)
            )
          )
        ).docs.map((doc) => ({ id: doc.id, ...doc.data() }))?.[0];
        if (!foundInDatabase) {
          await setDoc(
            doc(db, equipmentTable, equipmentData.id),
            equipmentData
          );
        } else {
          await updateDoc(doc(db, equipmentTable, equipmentData.id), {
            ...foundInDatabase,
            ...equipmentData,
            deleted: false,
          });
        }

        this.$toast("Kalusto lisätty onnistuneesti!", { type: "success" });
        this.dialogEquipment = false;
        this.editEquipment(equipmentData.id);
      } catch (e) {
        console.error(e);
        this.$toast("Kaluston tallennus epäonnistui", { type: "error" });
      }
      this.loadingEquipmentSave = false;
    },
    confirmTypeSelection(type) {
      if (type) {
        this.equipment.typeId = type.id;
        this.equipment.typeFullTree = type._fullTree;
      } else {
        this.equipment.typeId = null;
        this.equipment.typeFullTree = null;
      }
    },
    openTypeDialog() {
      this.$refs.kalustoTyyppiDialog.openTypeDialog();
    },
  },
  mounted() {
    this.fetchEquipmentTypes();
  },
};
</script>
