<script>
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestore as db } from "@/plugins/firebase.app";

const table = "kalusto_tyypit";

export default {
  name: "KalustoTyypitView",
  data() {
    return {
      equipmentTypes: [], // Raw flat data from Firestore
      treeData: [], // Hierarchical data for v-treeview
      openNodes: [],
      selectedNodes: [],
      search: "",
      dialog: false,
      dialogTitle: "",
      dialogData: {},
      loadingTypes: false,
      loadingSaveType: false,
    };
  },
  methods: {
    async fetchTypes() {
      this.loadingTypes = true;
      try {
        const snapshot = await getDocs(
          query(collection(db, table), where("deleted", "==", false))
        );
        this.equipmentTypes = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        this.buildTree();
      } catch (e) {
        console.error(e);
        this.$toast("Virhe haettaessa tietoja", { type: "error" });
      }
      this.loadingTypes = false;
    },

    // Build hierarchical data for the tree
    buildTree() {
      const map = new Map(
        this.equipmentTypes.map((type) => [
          type.id,
          { ...type, children: [], _fullTree: "" },
        ])
      );
      const tree = [];

      map.forEach((node) => {
        if (node.parent) {
          const parent = map.get(node.parent);
          if (parent) {
            node._fullTree = parent._fullTree
              ? [parent._fullTree, node.name].join(" -> ")
              : node.name;
            parent.children.push(node);
          }
        } else {
          node._fullTree = node.name;
          tree.push(node);
        }
      });
      this.treeData = tree;
    },

    // Add a new type
    addType(parent) {
      this.dialogTitle = "Lisää uusi tyyppi";
      this.dialogData = {
        name: "",
        parent: parent.id,
        deleted: false,
        _fullTree: parent._fullTree,
      };
      this.dialog = true;
    },

    // Edit an existing type
    editType(item) {
      this.dialogTitle = "Muokkaa tyyppiä";
      this.dialogData = { ...item };
      this.dialog = true;
    },

    // Save the type
    async saveType() {
      if (!this.dialogData.name.trim()) {
        this.$toast("Tyypin nimi on määritettävä", { type: "error" });
        return;
      }
      this.loadingSaveType = true;
      try {
        if (this.dialogData.id) {
          // Update existing type
          await updateDoc(doc(db, table, this.dialogData.id), this.dialogData);
        } else {
          // Add new type
          await addDoc(collection(db, table), this.dialogData);
        }
        this.dialog = false;
      } catch (e) {
        console.error(e);
        this.$toast("Virhe tallennuksessa", { type: "error" });
      }
      this.loadingSaveType = false;
      this.$emit("update");
      this.fetchTypes(); // Refresh data
    },

    // Delete a type
    async deleteType(item) {
      if (
        await this.$deleteConfirm(
          `Haluatko varmasti poistaa tyypin ${item.name}?`
        )
      ) {
        if (item.children && item.children.length > 0) {
          this.$toast("Tyyppiä ei voi poistaa, koska sillä on alityyppejä", {
            type: "error",
          });
          return;
        }
        await deleteDoc(doc(db, table, item.id));
        this.fetchTypes();
      }
    },
  },
  mounted() {
    this.fetchTypes();
  },
};
</script>

<template>
  <v-container>
    <v-card class="fill-height">
      <v-card-title>
        <v-icon class="mr-2">mdi-tag-text-outline</v-icon>
        Kalustotyypit
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Hae"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-progress-linear
          indeterminate
          v-if="loadingTypes"
        ></v-progress-linear>
        <v-treeview
          v-model="selectedNodes"
          :items="treeData"
          activatable
          item-value="id"
          item-text="name"
          open-on-click
          :open.sync="openNodes"
          :search="search"
        >
          <template v-slot:append="{ item }">
            <v-icon @click="addType(item)" class="mr-2">mdi-plus</v-icon>
            <v-icon @click="editType(item)" class="mr-2">mdi-pencil</v-icon>
            <v-icon @click="deleteType(item)">mdi-delete</v-icon>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>

    <!-- Add/Edit Dialog -->
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col cols="12"
              ><span class="text-subtitle-1 py-4">{{
                dialogData._fullTree
              }}</span></v-col
            >
          </v-row>
          <v-text-field
            label="Tyypin nimi"
            v-model="dialogData.name"
            autofocus
            required
            @keyup.enter="saveType"
          ></v-text-field>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" text>Sulje</v-btn>
          <v-btn color="primary" @click="saveType" :loading="loadingSaveType"
            >Tallenna</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
