<template>
  <v-row class="tehtavat">
    <v-col cols="12">
      <v-card class="mt-5">
        <v-card-title
          >Hallituksen tehtävät
          <v-spacer />
          <v-btn color="primary" rounded @click="newTehtava">
            <v-icon>mdi mdi-plus</v-icon>
            Uusi tehtävä</v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row align-content="center">
            <v-col cols="auto" class="align-content-center">
              <v-icon>mdi mdi-filter</v-icon>
            </v-col>
            <v-col cols="auto" class="align-content-center">
              <v-btn-toggle v-model="showOwnTasks" class="mr-4">
                <v-btn depressed small :value="true"> Vastuuhenkilönä </v-btn>
              </v-btn-toggle>
              <v-btn-toggle v-model="showCreatedByTasks" class="mr-4">
                <v-btn depressed small :value="true"> Luotu </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="auto">
              <v-select
                v-model="selectedStatus"
                :items="statusOptions"
                label="Tila"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="auto">
              <v-chip-group mandatory v-model="year">
                <v-chip :value="2028" v-if="thisYear >= 2028"> 2028 </v-chip>
                <v-chip :value="2027" v-if="thisYear >= 2027"> 2027 </v-chip>
                <v-chip :value="2026" v-if="thisYear >= 2026"> 2026 </v-chip>
                <v-chip :value="2025" v-if="thisYear >= 2025"> 2025 </v-chip>
                <v-chip :value="2024" v-if="thisYear >= 2024"> 2024 </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="filteredTehtavat"
                :items-per-page="-1"
                :item-class="getRowClass"
                :loading="loadingTehtavat"
              >
                <template v-slot:[`item.ilmoitus.dateStart`]="{ item }">
                  {{ $formatDate(item.ilmoitus?.dateStart) }}
                </template>
                <template v-slot:item.orderNumber="{ item }">
                  {{ item.orderNumber ? item.orderNumber + " §" : "" }}
                </template>
                <template v-slot:item.title="{ item }">
                  {{ item.title }}
                </template>
                <template v-slot:item.createdAt="{ item }">
                  {{ $formatDateAndHourMinute(item.createdAt) }}
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  {{ $formatDateAndHourMinute(item.updatedAt) }}
                </template>
                <template v-slot:item.deadline="{ item }">
                  <span
                    :class="[
                      isDateGone(item.deadline) &&
                        item.status !== 'READY' &&
                        'red--text',
                    ]"
                    >{{ $formatDate(item.deadline) }}</span
                  >
                </template>
                <template v-slot:item.status="{ item }">
                  <tehtava-status-bagde :status="item.status" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn icon @click="editTehtava(item)">
                    <v-icon>mdi mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <template v-slot:footer.prepend>
                  <v-btn
                    color="default"
                    text
                    rounded
                    small
                    @click="goToMallitehtavat"
                    ><v-icon small class="mr-2"
                      >mdi mdi-note-multiple-outline</v-icon
                    >Mallitehtävät</v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <tehtavat-hallitus-tehtava-dialog
        v-model="selectedTehtava"
        @reload="getTehtavat"
      />
    </v-col>
  </v-row>
</template>

<script>
import api from "@/api";
import { orderBy } from "lodash";
import TehtavatHallitusTehtavaDialog from "@/views/TehtavatHallitusTehtavaDialog.vue";
import dayjs from "dayjs";
import TehtavaStatusBagde from "@/views/TehtavaStatusBagde.vue";

export default {
  name: "TehtavatHallitus",
  components: { TehtavaStatusBagde, TehtavatHallitusTehtavaDialog },
  data() {
    return {
      // eslint-disable-next-line no-undef
      loadingUsers: false,
      users: [],
      loadingTehtavat: false,
      tehtavat: [],
      selectedTehtava: null,
      showOwnTasks: true,
      showCreatedByTasks: false,
      selectedStatus: null,
      year: dayjs().year(),
      thisYear: dayjs().year(),
      statusOptions: [
        { text: "Kaikki", value: null },
        { text: "Ei aloitettu", value: "NOT_STARTED" },
        { text: "Kesken", value: "IN_PROGRESS" },
        { text: "Valmis", value: "READY" },
      ],
    };
  },

  computed: {
    headers() {
      return [
        { text: "Kokous", value: "ilmoitus.title" },
        { text: "Kokous alkaa", value: "ilmoitus.dateStart" },
        { text: "§", value: "orderNumber" },
        { text: "Tehtävä", value: "title" },
        { text: "Vastuuhenkilö", value: "assignedToFormatted" },
        { text: "Tila", value: "status" },
        { text: "Määräaika", value: "deadline" },
        //{ text: "Luotu", value: "createdAt" },
        //{ text: "Päivitetty", value: "updatedAt" },
        { text: "", value: "actions" },
      ];
    },
    filteredTehtavat() {
      return this.tehtavat
        .filter((task) => {
          const matchesOwnTasks =
            !this.showOwnTasks ||
            task.assignedTo?.some((a) => a.sub === this.currentUserUid);
          const matchesCreatedBy =
            !this.showCreatedByTasks ||
            task.createdBy?.sub === this.currentUserUid;
          const matchesStatus =
            !this.selectedStatus || task.status === this.selectedStatus;
          const matchesYear =
            !this.year ||
            (this.year &&
              ((task.ilmoitus?.dateStart &&
                dayjs(task.ilmoitus.dateStart).year() === this.year) ||
                (task.deadline && dayjs(task.deadline).year() === this.year)));
          return (
            matchesOwnTasks && matchesStatus && matchesCreatedBy && matchesYear
          );
        })
        .map((task) => {
          if (task.assignedTo.some((a) => a.sub === this.currentUserUid)) {
            task._assignedToSelf = true;
          }
          return task;
        });
    },
    currentUserUid() {
      // Assume the user's email is accessible via an authenticated user context.
      return this.$user?.uid || "";
    },
  },
  async mounted() {
    await Promise.all([this.reloadUsers(), this.getTehtavat()]);
    if (this.$route.query.tehtava) {
      await this.editTehtava(
        this.tehtavat.find((t) => t.id === this.$route.query.tehtava)
      );
    }
  },
  watch: {
    selectedTehtava() {
      if (this.selectedTehtava && this.selectedTehtava.id) {
        if (this.$route.query.tehtava !== this.selectedTehtava.id) {
          this.$router.push({
            query: { tab: "tehtavat", tehtava: this.selectedTehtava.id },
          });
        }
      } else {
        this.$router.push({ query: { tab: "tehtavat" } });
      }
    },
    [`$route.query.tehtava`](tehtavaId) {
      if (tehtavaId) {
        this.editTehtava(this.tehtavat.find((t) => t.id === tehtavaId));
      } else {
        this.selectedTehtava = null;
      }
    },
    showCreatedByTasks() {
      if (this.showCreatedByTasks) {
        this.showOwnTasks = false;
      }
    },
    showOwnTasks() {
      if (this.showOwnTasks) {
        this.showCreatedByTasks = false;
      }
    },
  },
  methods: {
    getRowClass(item) {
      return item._assignedToSelf ? "assigned-to-self" : "";
    },
    isDateGone(date) {
      return dayjs(date).isBefore(dayjs());
    },
    goToMallitehtavat() {
      this.$router.push({ name: "HallitusMalliTehtavat" });
    },
    newTehtava() {
      this.selectedTehtava = {};
    },
    translateStatus(status) {
      switch (status) {
        case "NOT_STARTED":
          return "Ei aloitettu";
        case "IN_PROGRESS":
          return "Kesken";
        case "READY":
          return "Valmis";
        default:
          return status;
      }
    },
    async reloadUsers() {
      this.loadingUsers = true;
      let response = (await api.usersList()).data;
      const users = orderBy(
        response.users,
        ["displayName", "email"],
        ["asc", "asc"]
      );
      users.forEach((u) => {
        u.text = u.displayName;
        u.value = u.email;
      });
      this.users = users;
      this.loadingUsers = false;
    },
    async getTehtavat() {
      this.loadingTehtavat = true;
      try {
        const tehtavat = orderBy(
          (await api.tehtavaHallitusList({ type: "tehtava", year: this.year }))
            ?.data,
          ["deadline", "orderNumber"],
          ["asc"]
        );
        tehtavat.forEach((t) => {
          t.assignedToFormatted = t.assignedTo
            .map((a) => {
              return a.user;
            })
            .filter(Boolean)
            .join(", ");
        });
        this.tehtavat = tehtavat;
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävien haku ei onnistunut", { type: "error" });
      }
      this.loadingTehtavat = false;
    },
    async editTehtava(tehtava) {
      this.selectedTehtava = tehtava;
    },
  },
};
</script>
<style lang="scss">
.tehtavat {
  .assigned-to-self {
    background-color: #f1f1f1;
  }
}
</style>
