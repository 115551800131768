<template>
  <basic-page
    title="Hallitus"
    v-if="$isHallitus"
    :fallback="{ name: 'Hallitus' }"
    force-fallback
    prev
  >
    <v-row class="fill-height">
      <v-col cols="12" lg="12" offset-xl="2" xl="8">
        <v-row>
          <v-col cols="12">
            <v-card class="mt-5">
              <v-card-title>
                Mallitehtävät
                <v-spacer />
                <v-btn color="primary" rounded @click="newTehtava">
                  <v-icon>mdi mdi-plus</v-icon>
                  Uusi tehtävä</v-btn
                >
              </v-card-title>
              <v-divider />

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers"
                      :items="filteredTehtavat"
                      :items-per-page="-1"
                      :loading="loadingTehtavat"
                    >
                      <template v-slot:item.createdAt="{ item }">
                        {{ $formatDateAndHourMinute(item.createdAt) }}
                      </template>
                      <template v-slot:item.updatedAt="{ item }">
                        {{ $formatDateAndHourMinute(item.updatedAt) }}
                      </template>
                      <template v-slot:item.status="{ item }">
                        <v-chip
                          v-if="item.status"
                          :color="
                            item.status === 'NOT_STARTED'
                              ? 'red lighten-2'
                              : item.status === 'IN_PROGRESS'
                              ? 'orange'
                              : 'green'
                          "
                          small
                          >{{ translateStatus(item.status) }}</v-chip
                        >
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-btn icon @click="editTehtava(item)">
                          <v-icon>mdi mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <tehtavat-hallitus-tehtava-dialog
              v-model="selectedTehtava"
              @reload="getTehtavat"
              type="malli"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import api from "@/api";
import { orderBy } from "lodash";
import TehtavatHallitusTehtavaDialog from "@/views/TehtavatHallitusTehtavaDialog.vue";
import BasicPage from "@/components/BasicPage.vue";

export default {
  name: "TehtavatHallitus",
  components: { BasicPage, TehtavatHallitusTehtavaDialog },
  data() {
    return {
      // eslint-disable-next-line no-undef
      loadingUsers: false,
      users: [],
      loadingTehtavat: false,
      tehtavat: [],
      selectedTehtava: null,
      showOwnTasks: true,
      selectedStatus: null,
      statusOptions: [
        { text: "Kaikki", value: null },
        { text: "Ei aloitettu", value: "NOT_STARTED" },
        { text: "Kesken", value: "IN_PROGRESS" },
        { text: "Valmis", value: "DONE" },
      ],
    };
  },

  computed: {
    headers() {
      return [
        { text: "Järjestys", value: "orderNumber" },
        { text: "Tehtävä", value: "title" },
        { text: "Vastuuhenkilö", value: "assignedToFormatted" },
        /*{ text: "Tila", value: "status" },*/
        /*{ text: "Luotu", value: "createdAt" },*/
        /*{ text: "Päivitetty", value: "updatedAt" },*/
        { text: "", value: "actions" },
      ];
    },
    filteredTehtavat() {
      return orderBy(
        this.tehtavat.filter((task) => {
          return true;
        }),
        ["orderNumber"],
        ["asc"]
      );
    },
    currentUserUid() {
      // Assume the user's email is accessible via an authenticated user context.
      return this.$user?.uid || "";
    },
  },
  async mounted() {
    await Promise.all([this.reloadUsers(), this.getTehtavat()]);
  },
  watch: {},
  methods: {
    newTehtava() {
      this.selectedTehtava = {};
    },
    translateStatus(status) {
      switch (status) {
        case "NOT_STARTED":
          return "Ei aloitettu";
        case "IN_PROGRESS":
          return "Kesken";
        case "READY":
          return "Valmis";
        default:
          return status;
      }
    },
    async reloadUsers() {
      this.loadingUsers = true;
      let response = (await api.usersList()).data;
      const users = orderBy(
        response.users,
        ["displayName", "email"],
        ["asc", "asc"]
      );
      users.forEach((u) => {
        u.text = u.displayName;
        u.value = u.email;
      });
      this.users = users;
      this.loadingUsers = false;
    },
    async getTehtavat() {
      this.loadingTehtavat = true;
      try {
        const tehtavat = (await api.tehtavaHallitusList({ type: "malli" }))
          ?.data;
        tehtavat.forEach((t) => {
          t.assignedToFormatted = t.assignedTo
            .map((a) => {
              return a.user;
            })
            .filter(Boolean)
            .join(", ");
        });
        this.tehtavat = tehtavat;
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävien haku ei onnistunut", { type: "error" });
      }
      this.loadingTehtavat = false;
    },
    async editTehtava(tehtava) {
      this.selectedTehtava = tehtava;
    },
  },
};
</script>
<style lang="scss"></style>
