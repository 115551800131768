import Vue from "vue";
import "@babel/polyfill";
import "./registerServiceWorker";
import App from "./App.vue";

import vuetify from "./plugins/vuetify";
import globals from "./plugins/globals";
import dayjs from "dayjs";
import { onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { auth } from "@/plugins/firebase.app";
import store from "@/store";
import router from "@/router";

require("@/plugins/firebase.app");
require("dayjs/locale/fi");
Vue.config.productionTip = false;

let localizedFormat = require("dayjs/plugin/localizedFormat");
let advancedFormat = require("dayjs/plugin/advancedFormat");
let weekOfYear = require("dayjs/plugin/weekOfYear");
let isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
let duration = require("dayjs/plugin/duration");
let isoWeek = require("dayjs/plugin/isoWeek");
let utc = require("dayjs/plugin/utc");
let timezone = require("dayjs/plugin/timezone");
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.locale("fi");

let app = null;

async function initUser() {
  await new Promise((resolve) =>
    onAuthStateChanged(auth, async (user) => {
      //console.log("onAuthStateChanged", { user, auth });
      //if (process.env.NODE_ENV === "development") {
      //console.log("onAuthStateChanged", { user, auth });
      //}
      if (user) {
        await auth.currentUser.getIdToken(true);
        await store.dispatch("updateUser", { currentUser: auth.currentUser });

        const hash = window.location.hash;
        // Only redirect to "Ilmoitustaulu" if no other route is active
        if (hash === "" || hash === "#/" || hash === "#/login") {
          await router.push({ name: "Ilmoitustaulu" });
        }
        //router.push({ name: "Ilmoitustaulu" });
      } else {
        console.log("Logged out", user);
      }
      resolve();
    })
  );
  onIdTokenChanged(auth, async (user) => {
    console.log("onIdTokenChanged", user);
    if (user) {
      // User is signed in or token was refreshed.
      await store.dispatch("updateUser", { currentUser: user });
    }
  });
}

async function initApp() {
  app = new Vue({
    store,
    router,
    vuetify,
    globals,
    render: (h) => h(App),
    created() {},
  });
  app.$mount("#app");
}

initUser().then(initApp);
