<template>
  <basic-page
    v-if="$isHallitus"
    :fallback="{ name: 'Hallitus' }"
    force-fallback
    prev
    :title="`Hallitus: Ilmoitus - ${ilmoitus.title || ''} ${
      ilmoitus.orderNumber || ''
    }`"
  >
    <v-row class="fill-height">
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab> Ilmoitus </v-tab>
          <v-tab> Tehtävät </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-col cols="12">
              <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
              <v-form ref="form">
                <v-card v-if="!loading" flat>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="ilmoitus.type"
                          :items="types"
                          item-text="label"
                          item-value="value"
                          label="Tyyppi"
                          outlined
                          hide-details
                          :rules="[$rules.required]"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        :md="ilmoitus.type === 'kokous' ? 9 : 12"
                      >
                        <v-text-field
                          v-model="ilmoitus.title"
                          :rules="[$rules.required]"
                          label="Otsikko"
                          outlined
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" v-if="ilmoitus.type === 'kokous'">
                        <v-text-field
                          v-model="ilmoitus.orderNumber"
                          :rules="[$rules.required]"
                          label="Kokouksen järjestysnumero"
                          outlined
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="ilmoitus.content"
                          auto-grow
                          label="Kuvaus"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <modal-picker
                          v-model="ilmoitus.dateStart"
                          label="Aloituspäivämäärä"
                        />
                      </v-col>
                      <v-col cols="12" lg="3">
                        <modal-picker
                          v-model="ilmoitus.timeStart"
                          label="Aloitusaika"
                          type="time"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <modal-picker
                          v-model="ilmoitus.dateEnd"
                          :error="diff < 0"
                          label="Lopetuspäivämäärä"
                        />
                      </v-col>
                      <v-col cols="12" lg="3">
                        <modal-picker
                          v-model="ilmoitus.timeEnd"
                          :error="diff < 0"
                          label="Lopetusaika"
                          type="time"
                        />
                      </v-col>
                      <v-col v-if="diff < 0" cols="12">
                        <v-alert type="error"
                          >Lopetusajan pitää olla aloitusajan jälkeen
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider />
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-select
                          v-model="ilmoitus.departments"
                          :items="departments"
                          multiple
                          item-text="label"
                          item-value="value"
                          label="Osastot"
                          outlined
                          :rules="[$rules.required]"
                        ></v-select>
                      </v-col>
                      <v-col v-if="ilmoitus.id" cols="12">
                        <file-upload ref="upload"></file-upload>
                        <template v-for="(file, i) in ilmoitus.files">
                          <v-list-item-subtitle
                            :key="i"
                            class="text-wrap info--text d-flex align-center mb-3 cursor-pointer"
                            @click="openFile(file)"
                          >
                            <v-icon>mdi mdi-paperclip</v-icon>
                            <div>{{ file.filename }}</div>
                            <v-btn
                              :loading="loadingDelete"
                              icon
                              @click.stop="deleteFile(file)"
                            >
                              <v-icon color="error"> mdi mdi-delete</v-icon>
                            </v-btn>
                          </v-list-item-subtitle>
                        </template>
                      </v-col>
                    </v-row>
                    <v-divider
                      v-if="
                        ilmoitus.type === 'ilmoittautuminen' ||
                        ilmoitus.type === 'kokous'
                      "
                    />
                    <v-row
                      v-if="
                        ilmoitus.type === 'ilmoittautuminen' ||
                        ilmoitus.type === 'kokous'
                      "
                    >
                      <v-col class="mt-6" cols="12">
                        <h3>Ilmoittautumisen asetukset</h3>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="ilmoitus.participantCount"
                          hint="Jätä tyhjäksi mikäli et halua rajoittaa osallistujamäärää."
                          label="Max osallistujamäärä"
                          outlined
                          persistent-hint
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text v-if="$id(ilmoitus)">
                    <ilmoittautuminen-hallitus
                      :ilmoitus="ilmoitus"
                      admin
                      @reload="reloadIlmoitus"
                    />
                  </v-card-text>
                </v-card>
              </v-form>
            </v-col>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-divider />
              <v-card-text>
                <v-col
                  cols="12"
                  v-if="ilmoitus.type === 'kokous' && loadingTehtavat"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-col>
              </v-card-text>
              <v-card-text
                v-if="
                  ilmoitus.type === 'kokous' &&
                  tehtavat.length > 0 &&
                  !loadingTehtavat
                "
              >
                <h3>Kokouksen tehtävät</h3>

                <v-row class="mt-2">
                  <v-col cols="12">
                    <!-- Mallitehtavat -->
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th style="width: 50px"></th>
                            <th class="text-left">Järjestysnumero §</th>
                            <th class="text-left">Otsikko</th>
                            <th class="text-left">Vastuuhenkilöt</th>
                            <th class="text-left">Määräpäivä</th>
                            <th class="text-left">Tila</th>
                            <th style="width: 24px"></th>
                            <!--<th class="text-left">Kuvaus</th>-->
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in tehtavat" :key="item.id">
                            <td>
                              <div class="d-flex flex-column">
                                <v-btn
                                  icon
                                  x-small
                                  v-if="index > 0"
                                  @click="moveTehtava(item, index, -1)"
                                  ><v-icon>mdi mdi-arrow-up-thin</v-icon></v-btn
                                >

                                <v-btn
                                  icon
                                  x-small
                                  class="ml-2"
                                  v-if="index < tehtavat.length - 1"
                                  @click="moveTehtava(item, index, 1)"
                                  ><v-icon
                                    >mdi mdi-arrow-down-thin</v-icon
                                  ></v-btn
                                >
                              </div>
                            </td>
                            <td>
                              {{
                                item.orderNumber ? `${item.orderNumber} §` : ""
                              }}
                            </td>
                            <td>{{ item.title }}</td>
                            <td>
                              {{
                                item.assignedTo &&
                                item.assignedTo
                                  .map((u) => u.user)
                                  .filter(Boolean)
                                  .join(", ")
                              }}
                            </td>
                            <td>{{ $formatDate(item.deadline) }}</td>
                            <td>
                              <tehtava-status-bagde :status="item.status" />
                            </td>
                            <td>
                              <v-btn icon @click="selectedTehtava = item">
                                <v-icon>mdi mdi-arrow-right</v-icon>
                              </v-btn>
                            </td>
                            <!--<td>{{ item.description }}</td>-->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-btn
                      icon
                      class="ml-4"
                      color="primary"
                      x-small
                      title="Tallenna tehtävien järjestykset"
                      v-if="dirtyTehtavatOrderNumber"
                      :loading="loadingSaveTehtavaOrderNumbers"
                      @click="saveTehtavaOrderNumbers"
                      ><v-icon>mdi mdi-content-save-outline</v-icon></v-btn
                    >
                    <v-btn
                      icon
                      class="ml-4"
                      color="primary"
                      x-small
                      title="Korjaa järjestysnumerovälit"
                      v-if="gapsInTehtavat"
                      :loading="loadingSaveTehtavaOrderNumbers"
                      @click="saveTehtavaOrderNumbers"
                      ><v-icon>mdi mdi-reload</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-text v-if="ilmoitus.type === 'kokous'">
                <h3>Kokoukseen liitettävät mallitehtävät</h3>
                Voit liittää kokoukseen alla olevia tehtäviä.
                <v-row class="mt-2">
                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      v-model="startOrderNumber"
                      label="Aloitusnumero"
                      style="width: 100px"
                      outlined
                      dense
                    />
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Valitse</th>
                            <th class="text-left">Järj.nro</th>
                            <th class="text-left">Otsikko</th>
                            <th class="text-left">Vastuuhenkilöt</th>
                            <!--<th class="text-left">Kuvaus</th>-->
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="filteredMalliTehtavat.length <= 0">
                            <td colspan="100%">Ei mallitehtäviä</td>
                          </tr>
                          <tr
                            v-for="item in filteredMalliTehtavat"
                            :key="item.id"
                          >
                            <td>
                              <!-- checkbox for selecting -->
                              <v-checkbox
                                v-model="item._selected"
                                hide-details
                                dense
                              ></v-checkbox>
                            </td>
                            <td style="width: 100px">
                              <v-text-field
                                dense
                                hide-details
                                outlined
                                type="number"
                                v-model="item._tempOrderNumber"
                              />
                            </td>
                            <td>{{ item.title }}</td>
                            <td>
                              <v-autocomplete
                                v-model="item.assignedTo"
                                outlined
                                dense
                                hide-details
                                multiple
                                :items="users"
                              />
                            </td>
                            <!--<td>{{ item.description }}</td>-->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <v-divider />
        <v-card flat class="mb-12">
          <v-card-actions>
            <v-btn
              v-if="$id(ilmoitus)"
              :loading="loadingDelete"
              color="error"
              @click="deleteIlmoitus"
              >Poista
            </v-btn>
            <v-spacer />
            <v-btn :loading="loadingSave" color="primary" @click="save"
              >Tallenna
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-divider />
        <tehtavat-hallitus-tehtava-dialog
          v-model="selectedTehtava"
          @reload="reloadTehtavat"
        />
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import IlmoittautuminenHallitus from "@/components/Hallitus/IlmoittautuminenHallitus.vue";
import FileUpload from "@/components/FileUpload";
import api from "@/api";
import ModalPicker from "@/components/ModalPicker.vue";
import dayjs from "dayjs";
import { getBlob, ref } from "firebase/storage";
import downloadjs from "downloadjs";
import { ilmoitusHallitusStorage } from "@/plugins/firebase.app";
import { orderBy } from "lodash";
import TehtavatHallitusTehtavaDialog from "@/views/TehtavatHallitusTehtavaDialog.vue";
import TehtavaStatusBagde from "@/views/TehtavaStatusBagde.vue";

export default {
  name: "Ilmoitus",
  components: {
    TehtavaStatusBagde,
    TehtavatHallitusTehtavaDialog,
    ModalPicker,
    FileUpload,
    IlmoittautuminenHallitus,
    BasicPage,
  },
  data() {
    return {
      ilmoitus: {
        title: null,
        content: null,
        orderNumber: null,
        type: "ilmoitus",
        participantCount: null,
        departments: [],
        dateStart: null,
        timeStart: null,
        dateEnd: null,
        timeEnd: null,
      },
      loadingMalliTehtavat: false,
      loadingTehtavat: false,
      loadingSaveTehtavaOrderNumbers: false,
      malliTehtavat: [],
      tehtavat: [],
      tab: 0,
      startOrderNumber: 1,
      dirtyTehtavatOrderNumber: false,
      tehtavaStartOrderNumber: null,
      selectedTehtava: null,
      types: [
        {
          value: "ilmoitus",
          label: "Ilmoitus",
        },
        {
          value: "ilmoittautuminen",
          label: "Ilmoittautuminen",
        },
        {
          value: "kokous",
          label: "Kokous",
        },
      ],
      files: [],
      loading: false,
      loadingSave: false,
      loadingDelete: false,

      users: [],
    };
  },
  computed: {
    diff() {
      if (
        this.ilmoitus.dateStart &&
        this.ilmoitus.timeStart &&
        this.ilmoitus.dateEnd &&
        this.ilmoitus.timeEnd
      ) {
        const startTime = dayjs(
          this.ilmoitus.date + " " + this.ilmoitus.time,
          "YYYY-MM-DD HH:mm"
        );
        const returnTime = dayjs(
          this.ilmoitus.dateReturn + " " + this.ilmoitus.timeReturn,
          "YYYY-MM-DD HH:mm"
        );
        return returnTime.diff(startTime);
      }
      return null;
    },
    departments() {
      return [
        {
          value: "hallitus",
          label: "Hallitus",
        },
      ];
    },
    id() {
      return this.$id(this.ilmoitus);
    },
    filteredMalliTehtavat: {
      get() {
        const foundTehtavat = this.tehtavat
          .map((t) => t.tehtavaMalliId)
          .filter(Boolean);
        return this.malliTehtavat.filter((t) => !foundTehtavat.includes(t.id));
      },
      set(value) {},
    },
    gapsInTehtavat() {
      return this.tehtavat.some((t, i) => {
        try {
          return parseInt(t.orderNumber) !== this.tehtavaStartOrderNumber + i;
        } catch (e) {
          console.error(e);
          return true;
        }
      });
    },
  },
  methods: {
    async saveTehtavaOrderNumbers() {
      this.loadingSaveTehtavaOrderNumbers = true;
      try {
        await api.tehtavaHallitusOrderNumber(
          this.tehtavat.map((r, i) => {
            return {
              id: r.id,
              orderNumber: this.tehtavaStartOrderNumber + i,
            };
          })
        );
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävien järjestysten tallennus epäonnistui", {
          type: "error",
        });
      }
      this.loadingSaveTehtavaOrderNumbers = false;
      this.dirtyTehtavatOrderNumber = false;
      this.reloadTehtavat();
    },
    moveTehtava(tehtava, index, direction) {
      const tehtavat = [...this.tehtavat];
      const newIndex = index + direction;
      tehtavat.splice(index, 1);
      tehtavat.splice(newIndex, 0, tehtava);
      this.tehtavat = tehtavat.map((t, i) => {
        t.orderNumber = this.tehtavaStartOrderNumber + i;
        return t;
      });
      this.dirtyTehtavatOrderNumber = true;
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.ilmoitus.type) {
        this.$toast("Valitse ilmoituksen tyyppi", {
          type: "error",
        });
        return;
      }
      if (this.ilmoitus.type === "kokous" && !this.ilmoitus.orderNumber) {
        this.$toast("Anna kokouksen järjestysnumero", {
          type: "error",
        });
        return;
      }
      this.loadingSave = true;
      const files = await this.$refs.upload?.getFiles();

      try {
        // eslint-disable-next-line no-undef
        const _ilmoitus = structuredClone(this.ilmoitus);
        try {
          // eslint-disable-next-line no-undef
          _ilmoitus.malliTehtavat = structuredClone(
            this.malliTehtavat.filter((t) => t._selected)
          ).map((tehtava) => {
            tehtava.assignedTo =
              tehtava.assignedTo
                ?.map((u) => {
                  const user = this.users.find((user) => user.email === u);
                  if (!user) return null;
                  return {
                    email: user.email,
                    sub: user.uid,
                    user: user.displayName,
                  };
                })
                .filter(Boolean) || [];
            return tehtava;
          });
        } catch (e) {
          console.error(e);
        }
        const ilmoitus = (await api.ilmoitusHallitusUpsert(_ilmoitus)).data;

        if (files) {
          for (let filesKey in files) {
            (
              await api.ilmoitusHallitusAddFile({
                ilmoitusId: ilmoitus.id,
                ...files[filesKey],
              })
            ).data;
          }
        }

        if (this.dirtyTehtavatOrderNumber && this.tehtavat?.length > 0) {
          await this.saveTehtavaOrderNumbers();
        }
        this.$toast("Ilmoitus tallennettu.", {
          type: "success",
        });
        this.malliTehtavat.forEach((t) => (t._selected = false));
        if (!this.id) {
          this.$router.push({
            name: "HallitusIlmoitusById",
            params: { id: this.$id(ilmoitus) },
          });
        }
        this.ilmoitus = ilmoitus;
        await this.reloadIlmoitus();
        await Promise.all([this.reloadMalliTehtavat(), this.reloadTehtavat()]);
      } catch (e) {
        this.$toast("Tallennus epäonnistui. Ole hyvä ja yritä uudelleen.", {
          type: "error",
        });
        console.error(e);
      }
      this.loadingSave = false;
    },
    async reloadUsers() {
      this.loadingUsers = true;
      let response = (await api.usersList()).data;
      const users = orderBy(
        response.users,
        ["displayName", "email"],
        ["asc", "asc"]
      );
      users.forEach((u) => {
        u.text = u.displayName;
        u.value = u.email;
      });
      this.users = users;
      this.loadingUsers = false;
    },
    async openFile(file) {
      try {
        const theBlob = await getBlob(ref(ilmoitusHallitusStorage, file.key));
        downloadjs(theBlob, file.filename, file.fileType);
      } catch (error) {
        console.error(error);
        this.$toast("Tiedostoa ei voitu avata. Yritä uudelleen.", {
          type: "error",
        });
      }
    },
    async deleteFile(file) {
      this.loadingDeleteFile = true;
      try {
        await api.ilmoitusHallitusDeleteFile(file);
        await this.reloadIlmoitus();
        this.$toast("Tiedosto poistettu", {
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.$toast("Tiedoston poistaminen epäonnistui", {
          type: "error",
        });
      }
      this.loadingDelete = false;
    },
    async reloadIlmoitus() {
      this.loading = true;
      const ilmoitus = (
        await api.ilmoitusHallitusGet({ id: this.$route.params.id })
      ).data;
      if (
        ilmoitus.participants?.findIndex((il) => il.sub === this.$user.uid) > -1
      ) {
        ilmoitus._participant = true;
      }
      this.ilmoitus = ilmoitus;
      this.loading = false;
    },
    async deleteIlmoitus() {
      this.loadingDelete = true;
      try {
        await api.ilmoitusHallitusDelete(this.ilmoitus);
        this.$toast("Ilmoitus poistettu", {
          type: "success",
        });
        this.$router.push({ name: "Hallitus" });
      } catch (e) {
        console.error(e);
        this.$toast("Poistaminen epäonnistui", {
          type: "error",
        });
      }
      this.loadingDelete = false;
    },
    async reloadMalliTehtavat() {
      this.loadingMalliTehtavat = true;
      try {
        this.malliTehtavat = orderBy(
          (await api.tehtavaHallitusList({ type: "malli" }))?.data?.map(
            (tehtava) => {
              tehtava._tempOrderNumber = tehtava.orderNumber;
              tehtava.assignedTo =
                tehtava.assignedTo?.map((u) => u.email) || [];
              tehtava._selected = false;
              return tehtava;
            }
          ),
          ["orderNumber"],
          ["asc"]
        );
      } catch (e) {
        console.error(e);
        this.$toast("Mallitehtävien haku ei onnistunut", { type: "error" });
      }
      this.loadingMalliTehtavat = false;
    },
    async reloadTehtavat() {
      if (!this.ilmoitus?.id) return true;
      this.loadingTehtavat = true;
      try {
        this.tehtavat = orderBy(
          (
            await api.tehtavaHallitusList({
              type: "tehtava",
              ilmoitusId: this.ilmoitus?.id,
            })
          )?.data?.map((tehtava) => {
            return tehtava;
          }),
          ["orderNumber"],
          ["asc"]
        );
        this.tehtavaStartOrderNumber = Math.min(
          ...this.tehtavat.map((t) => {
            try {
              return parseInt(t.orderNumber);
            } catch (e) {
              console.error(e);
              return 9999;
            }
          })
        );
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävien haku ei onnistunut", { type: "error" });
      }
      this.loadingTehtavat = false;
    },
  },

  watch: {
    startOrderNumber(val) {
      this.malliTehtavat.forEach((m) => {
        try {
          m._tempOrderNumber = parseInt(m.orderNumber) + parseInt(val) - 1;
        } catch (e) {
          console.error(e);
        }
      });
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      await this.reloadIlmoitus();
    } else {
      this.ilmoitus.departments = [this.$isHallitus && "hallitus"].filter(
        Boolean
      );
    }
    await Promise.all([
      this.reloadUsers(),
      this.reloadMalliTehtavat(),
      this.reloadTehtavat(),
    ]);
  },
};
</script>

<style lang="scss" scoped></style>
