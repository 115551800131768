<template>
  <basic-page title="Hallitus" v-if="$isHallitus">
    <v-row class="fill-height ilmoitustaulu-hallitus">
      <v-col cols="12" lg="12" offset-xl="2" xl="8">
        <v-tabs class="mb-5" v-model="tab">
          <v-tab href="#ilmoitustaulu"> Ilmoitustaulu </v-tab>
          <v-tab href="#kalenteri"> Kalenteri </v-tab>
          <v-tab href="#tiedostopankki"> Tiedostopankki </v-tab>
          <v-tab href="#tehtavat"> Tehtävät </v-tab>
        </v-tabs>
        <v-row>
          <v-tabs-items v-model="tab" class="background-none mb-5 w-100">
            <v-tab-item name="ilmoitustaulu" value="ilmoitustaulu" class="pa-3">
              <v-row class="mb-2">
                <v-col v-if="nextKokous" cols="12" md="12">
                  <v-card>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-4">Seuraava kokous</div>
                        <v-list-item-subtitle
                          class="text-h6 mb-1 black--text text-wrap"
                          >{{ nextKokous.title }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-wrap"
                          ><span
                            >{{
                              $formatWeekDayDateAndHourMinute(
                                $parseDateAsHelsinki(
                                  `${nextKokous.dateStart} ${
                                    nextKokous.timeStart || "00:00:00"
                                  }`
                                )
                              )
                            }}
                            <p
                              class="text-wrap mt-3"
                              style="white-space: pre-line !important"
                            >
                              {{ nextKokous.content }}
                            </p>
                          </span>
                          <p>
                            <template v-for="(file, i) in nextKokous.files">
                              <v-list-item-subtitle
                                :key="i"
                                class="text-wrap info--text d-flex align-center mb-3 mt-6 cursor-pointer"
                                @click="openFile(file)"
                              >
                                <v-icon>mdi mdi-paperclip</v-icon>
                                <div
                                  class="text-wrap"
                                  style="word-break: break-word"
                                >
                                  {{ file.filename }}
                                </div>
                              </v-list-item-subtitle>
                            </template>
                          </p>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-avatar>
                        <v-icon size="36">mdi mdi-gavel</v-icon>
                      </v-list-item-avatar>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
              <v-card class="mt-2">
                <v-card-title
                  >Hallituksen ilmoitustaulu
                  <v-spacer />
                  <div v-if="$isHallitus">
                    <v-btn color="primary" rounded @click="createIlmoitus">
                      <v-icon>mdi mdi-plus</v-icon> Uusi ilmoitus
                    </v-btn>
                  </div>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-subtitle>
                  <!-- Buttons for filtering filteredIlmoitukset type property kokous, ilmoitus, ilmoittautuminen -->
                  <v-row no-gutters justify="start" align-content="center">
                    <v-icon class="mr-2">mdi mdi-filter</v-icon>
                    <v-btn-toggle
                      v-model="eventFilter"
                      multiple
                      class="align-center"
                    >
                      <v-btn value="kokous" small>
                        <template v-if="$vuetify.breakpoint.mdAndUp"
                          >Kokoukset</template
                        >
                        <template v-else>
                          <v-icon>mdi mdi-gavel</v-icon>
                        </template>
                      </v-btn>
                      <v-btn value="ilmoitus" small>
                        <template v-if="$vuetify.breakpoint.mdAndUp"
                          >Ilmoitukset</template
                        >
                        <template v-else>
                          <v-icon>mdi mdi-exclamation-thick</v-icon>
                        </template>
                      </v-btn>
                      <v-btn value="ilmoittautuminen" small>
                        <template v-if="$vuetify.breakpoint.mdAndUp"
                          >Ilmoittautumiset</template
                        >
                        <template v-else>
                          <v-icon>mdi mdi-account-multiple</v-icon>
                        </template>
                      </v-btn>
                    </v-btn-toggle>

                    <v-spacer />
                    <v-switch
                      v-model="showOld"
                      class="ml-5 mt-n1"
                      dense
                      hide-details
                      inset
                      prepend-icon="mdi mdi-eye"
                      title="Näytä vanhat ilmoitukset"
                    >
                    </v-switch>
                  </v-row>
                </v-card-subtitle>
                <v-divider />
                <v-card-text v-if="loading">
                  <v-skeleton-loader
                    type="list-item-three-line,list-item-three-line,list-item-three-line,list-item-three-line,list-item-three-line"
                    width="100%"
                  />
                </v-card-text>
              </v-card>

              <template v-if="!loading">
                <template v-for="ilmoitus in filteredIlmoitukset">
                  <ilmoitus-hallitus-list-item
                    :key="ilmoitus.id"
                    :ilmoitus="ilmoitus"
                    @reload="reloadIlmoitukset"
                  />
                  <!--<v-divider :key="`${i}_2`" class="mb-4 mt-2" />-->
                </template>
              </template>
            </v-tab-item>
            <v-tab-item class="pa-3" name="kalenteri" value="kalenteri">
              <v-card>
                <v-card-text>
                  <span class="subtitle-1"
                    >Tänään on viikko {{ week }}, {{ today }}</span
                  >
                </v-card-text>
                <v-divider />

                <v-card-text>
                  <v-row class="fill-height">
                    <v-col>
                      <v-sheet height="64">
                        <v-toolbar flat>
                          <v-btn
                            class="mr-4"
                            color="grey darken-2"
                            outlined
                            @click="setToday"
                          >
                            Tänään
                          </v-btn>
                          <v-btn
                            color="grey darken-2"
                            fab
                            small
                            text
                            @click="prev"
                          >
                            <v-icon small> mdi-chevron-left</v-icon>
                          </v-btn>
                          <v-btn
                            color="grey darken-2"
                            fab
                            small
                            text
                            @click="next"
                          >
                            <v-icon small> mdi-chevron-right</v-icon>
                          </v-btn>
                          <v-toolbar-title v-if="$refs.calendar">
                            {{
                              $formatMonthYear(
                                $refs.calendar.value || Date.now()
                              )
                            }}
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-menu bottom right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                outlined
                                color="grey darken-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span>{{ typeToLabel[calendarType] }}</span>
                                <v-icon right> mdi-menu-down </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="calendarType = 'day'">
                                <v-list-item-title>Päivä</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="calendarType = 'week'">
                                <v-list-item-title>Viikko</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="calendarType = 'month'">
                                <v-list-item-title>Kuukausi</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-toolbar>
                      </v-sheet>
                      <v-sheet height="600">
                        <v-calendar
                          ref="calendar"
                          v-model="focus"
                          :events="filteredEvents"
                          :weekdays="weekdays"
                          color="primary"
                          :type="calendarType"
                          @click:event="showEvent"
                        ></v-calendar>
                        <v-menu
                          v-model="selectedOpen"
                          :activator="selectedElement"
                          :close-on-content-click="false"
                          offset-x
                        >
                          <v-card color="grey lighten-4" flat min-width="250px">
                            <v-toolbar :color="selectedEvent.color" dark>
                              <span>{{ selectedEvent.name }} </span>
                              <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text>
                              <div v-if="selectedEvent.dateStart">
                                <span class="text-subtitle-2">
                                  {{
                                    $capitalFirstLetter(
                                      $formatWeekDay(
                                        selectedEvent.dateStart +
                                          " " +
                                          (selectedEvent.timeStart ||
                                            "00:00:00")
                                      )
                                    )
                                  }}
                                  {{
                                    $formatDateAndHourMinute(
                                      selectedEvent.dateStart +
                                        " " +
                                        (selectedEvent.timeStart || "00:00:00")
                                    )
                                  }}</span
                                >

                                <span
                                  v-if="selectedEvent.dateEnd"
                                  class="text-subtitle-2"
                                >
                                  -
                                  {{
                                    $capitalFirstLetter(
                                      $formatWeekDay(
                                        selectedEvent.dateEnd +
                                          " " +
                                          (selectedEvent.timeEnd || "00:00:00")
                                      )
                                    )
                                  }}
                                  {{
                                    $formatDateAndHourMinute(
                                      selectedEvent.dateEnd +
                                        " " +
                                        (selectedEvent.timeEnd || "00:00:00")
                                    )
                                  }}</span
                                >
                              </div>
                            </v-card-text>
                            <v-card-text>
                              <span v-html="selectedEvent.details"></span>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                color="secondary"
                                text
                                @click="selectedOpen = false"
                              >
                                Sulje
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
              </v-card>
            </v-tab-item>
            <v-tab-item
              class="pa-3"
              name="tiedostopankki"
              value="tiedostopankki"
            >
              <tiedostopankki-hallitus />
            </v-tab-item>
            <v-tab-item class="pa-3" name="tehtavat" value="tehtavat">
              <tehtavat-hallitus />
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";
import IlmoitusHallitusListItem from "@/components/Hallitus/IlmoitusHallitusListItem.vue";
import api from "@/api";
import { orderBy } from "lodash";
import { getBlob, ref } from "firebase/storage";
import { ilmoitusHallitusStorage } from "@/plugins/firebase.app";
import downloadjs from "downloadjs";
import TiedostopankkiHallitus from "@/views/TiedostopankkiHallitus.vue";
import TehtavatHallitus from "@/views/TehtavatHallitus.vue";

export default {
  name: "IlmoitustauluHallitus",
  components: {
    TehtavatHallitus,
    TiedostopankkiHallitus,
    BasicPage,
    IlmoitusHallitusListItem,
  },
  data() {
    return {
      ilmoitukset: [],
      loading: false,
      focus: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      permissionMessages: [],
      eventFilter: [],
      showOld: false,
      tab: "ilmoitustaulu",
      typeToLabel: {
        month: "Kuukausi",
        week: "Viikko",
        day: "Päivä",
      },
      isNotificationsSupported: "Notification" in window,
      calendarType: "month",
      loadingTehtavat: false,
      tehtavat: [],
    };
  },
  watch: {
    tab() {
      if (this.$route?.query?.tab !== this.tab)
        this.$router.push({ query: { tab: this.tab } });
    },
    "$route.query.tab": {
      immediate: true,
      handler(newTab) {
        // Convert query parameter to number if your tab index is numeric
        this.tab = newTab ? newTab : "ilmoitustaulu";
      },
    },
  },
  created() {
    if (this.$route?.query?.tab) {
      this.tab = this.$route.query.tab;
    }
  },
  computed: {
    ownTehtavat() {
      return this.tehtavat.filter((task) => {
        return task.assignedTo.some((a) => a.sub === this.$user.uid);
      });
    },
    filteredIlmoitukset() {
      // eslint-disable-next-line no-undef
      let ilmoitukset = structuredClone(this.ilmoitukset);
      ilmoitukset.forEach((i) => {
        i._date = [i.dateStart, i.timeEnd, i.createdAt, i.created]
          .filter(Boolean)
          .join(" ");
      });
      if (this.eventFilter.length > 0) {
        ilmoitukset = ilmoitukset.filter((ilmoitus) =>
          this.eventFilter.includes(ilmoitus.type)
        );
      }
      if (!this.showOld) {
        ilmoitukset = ilmoitukset.filter((ilmoitus) => {
          try {
            if (ilmoitus.dateEnd) {
              return dayjs(
                this.$parseDateAsHelsinki(
                  `${ilmoitus.dateEnd} ${ilmoitus.timeEnd || "23:59:59"}`
                )
              ).isAfter(dayjs());
            } else if (ilmoitus.dateStart) {
              return dayjs(
                this.$parseDateAsHelsinki(
                  `${ilmoitus.dateStart} ${ilmoitus.timeStart || "00:00:00"}`
                )
              ).isAfter(dayjs());
            }
          } catch (e) {
            console.error(e);
            return true;
          }
          return true;
        });
      }
      return orderBy(ilmoitukset, ["_date"], ["asc"]);
    },
    filteredEvents() {
      // eslint-disable-next-line no-undef
      let ilmoitukset = structuredClone(this.ilmoitukset);
      ilmoitukset.forEach((i) => {
        i._date = [i.dateStart, i.timeEnd, i.createdAt, i.created]
          .filter(Boolean)
          .join(" ");
      });
      return [
        ...[
          ilmoitukset
            ?.filter((e) => e.dateStart)
            .map((e) => {
              return {
                ...e,
                name: e.title,
                start: dayjs(
                  e.dateStart + " " + (e.timeStart || "00:00:00")
                ).toDate(),
                end: dayjs(
                  e.dateEnd
                    ? e.dateEnd + " " + (e.timeEnd || "00:00:00")
                    : e.dateStart + " " + (e.timeStart || "00:00:00")
                ).toDate(),
                timed: true,
                details: e.content,
                type: "ilmoitus",
              };
            }) || [],
        ].flatMap((e) => e),
        ...this.ownTehtavat
          .filter((t) => t.deadline)
          .map((t) => {
            return {
              ...t,
              name: `Tehtävä: ${t.title}`,
              start: dayjs(t.deadline + " 00:00:00").toDate(),
              end: dayjs(t.deadline + " 00:00:00").toDate(),
              timed: false,
              details: t.description,
              type: "tehtava",
              color:
                t.status === "READY"
                  ? "success"
                  : t.status === "IN_PROGRESS"
                  ? "warning"
                  : "error",
            };
          }),
      ];
    },
    today() {
      return dayjs().format("LLLL");
    },
    week() {
      return dayjs().format("w");
    },

    nextKokous() {
      const today = dayjs();
      const nextKokous = [...this.ilmoitukset]
        .reverse()
        .filter((i) => i.type === "kokous")
        .find(
          (h) =>
            h &&
            this.$parseDateAsHelsinki(
              `${h.dateStart} ${h.timeStart || "00:00:00"}`
            ).isAfter(today)
        );
      return nextKokous;
    },
  },
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    setToday() {
      this.focus = "";
    },

    async reloadIlmoitukset() {
      if (this.ilmoitukset.length === 0) {
        this.loading = true;
      }
      try {
        const ilmoitukset = (await api.ilmoituksetHallitusList()).data;

        ilmoitukset.forEach((ilmoitus) => {
          if (
            ilmoitus.participants?.findIndex(
              (il) => il.sub === this.$user.uid
            ) > -1
          ) {
            ilmoitus._participant = true;
          }
        });
        this.ilmoitukset = ilmoitukset;
      } catch (e) {
        console.error(e);
        this.$toast("Ilmoitusten haku epäonnistui.", {
          type: "error",
        });
      }
      this.loading = false;
    },
    reloadAll() {
      this.reloadIlmoitukset();
      this.reloadTehtavat();
    },
    createIlmoitus() {
      this.$router.push({ name: "HallitusIlmoitus" });
    },
    async openFile(file) {
      try {
        const theBlob = await getBlob(ref(ilmoitusHallitusStorage, file.key));
        downloadjs(theBlob, file.filename, file.fileType);
      } catch (error) {
        console.error(error);
        this.$toast("Tiedostoa ei voitu avata. Yritä uudelleen.", {
          type: "error",
        });
      }
    },
    async reloadTehtavat() {
      this.loadingTehtavat = true;
      try {
        const tehtavat = (await api.tehtavaHallitusList({ type: "tehtava" }))
          ?.data;
        tehtavat.forEach((t) => {
          t.assignedToFormatted = t.assignedTo
            .map((a) => {
              return a.user;
            })
            .filter(Boolean)
            .join(", ");
        });
        this.tehtavat = tehtavat;
      } catch (e) {
        console.error(e);
        this.$toast("Tehtävien haku ei onnistunut", { type: "error" });
      }
      this.loadingTehtavat = false;
    },
  },
  mounted() {
    this.reloadAll();
  },
};
</script>

<style lang="scss" scoped>
.align-self-start {
  align-selft: flext-start;
}
.background-none {
  background-color: transparent;
}
</style>
<style lang="scss">
.ilmoitustaulu-hallitus .v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}
</style>
